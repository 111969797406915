let baseURL;

if(process.env.NODE_ENV === 'development'){
    baseURL = 'https://sandboxapi.immarsify.com/api'
} else {
    baseURL = 'https://api.immarsify.com/api'
}

export {
    baseURL
}


// export const baseURL = 'https://sandboxapi.immarsify.com/api'


// export const baseURL = 'https://api.immarsify.com/api'
