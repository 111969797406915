import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BsThreeDots } from 'react-icons/bs';
import { Divider } from '@mui/material';
import { FaEye } from 'react-icons/fa';
import { CiEdit } from 'react-icons/ci';
import { RiDeleteBin7Fill } from 'react-icons/ri';
import InfoDrawer from './drawer/Drawer';
import { IoChevronForwardSharp } from 'react-icons/io5';
import { ConfirmDeleteModal } from './modal/DeleteLead';

export default function ActionMenu({enquiry, fetchEnquiries }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerType, setDrawerType] = React.useState('view');
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenDrawer = (type) => {
    setAnchorEl(null); // Close the menu when opening the drawer
    setDrawerType(type);
    setOpenDrawer(true);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null); // Just close the menu
  };



  return (
    <div className="flex justify-center">
      <BsThreeDots
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="cursor-pointer"
      />
        
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu} // Close the menu without opening the drawer
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleOpenDrawer('view'); // Open the drawer with 'view' type
          }}
          sx={{ fontSize: '14px' }}
        >
          <FaEye className="mr-2" /> View
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleOpenDrawer('edit'); // Open the drawer with 'edit' type
          }}
          sx={{ fontSize: '14px' }}
        >
          <CiEdit className="mr-2" /> Edit
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(null); // Close the menu
            setOpenDeleteModal(true); // Open the delete modal
          }}
          sx={{ fontSize: '14px' }}
        >
          <RiDeleteBin7Fill className="mr-2" /> Delete
        </MenuItem>
      </Menu>
      <InfoDrawer
        openDrawer={openDrawer}
        setOpenDeleteModal={setOpenDeleteModal}
        setOpenDrawer={setOpenDrawer}
        drawerType={drawerType}
        setDrawerType={setDrawerType}
        enquiry={enquiry}
        fetchEnquiries={fetchEnquiries}
        // labels={labels}
      />
      <ConfirmDeleteModal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        // onConfirm={() => {}}
          enquiry={enquiry}
          fetchEnquiries={fetchEnquiries}
      />
    </div>
  );
}