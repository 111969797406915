import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Chip } from "@mui/material";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { errorToaster, successToaster } from "../../ReactToastify";

import { useSelector } from "react-redux";
import { instance } from "../../../utils/axios";

// ConfirmationModal Component
const ConfirmationModal = ({ open, onClose, onConfirm, labelName }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete the label "{labelName}"?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirm();
            onClose();
          }}
          color="error"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// LabelManager Component
const LabelManager = ({
  openLabel,
  setOpenLabel,
  // setLabels,
  // labels,
  fetchLabels,
}) => {
  const token = useSelector((state) => state.auth.token);
  const labels = useSelector((state) => state.labels.labels);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editingLabel, setEditingLabel] = useState(null);
  const [newLabel, setNewLabel] = useState({
    name: "",
    type: "status",
    color: "#000000",
  });
  const [selectedType, setSelectedType] = useState("All");
  const [filteredLabels, setFilteredLabels] = useState([]);
  const [labelToDelete, setLabelToDelete] = useState(null);

  useEffect(() => {
    if (selectedType === "All") {
      setFilteredLabels(labels);
      return;
    }
    setFilteredLabels(labels.filter((label) => label.type === selectedType));
  }, [labels, selectedType]);

  const handleOpenDialog = (label = null) => {
    if (label) {
      setEditingLabel(label);
      setNewLabel({
        name: label.name,
        type: label.type,
        color: label.color,
      });
    } else {
      setEditingLabel(null);
      setNewLabel({ name: "", type: "status", color: "#000000" });
    }
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setEditingLabel(null);
    setNewLabel({ name: "", type: "status", color: "#000000" });
  };

  const handleSaveLabel = async () => {
    if (newLabel.name.trim() === "") {
      errorToaster("Label cannot be empty!");
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      if (editingLabel) {
        // Update existing label
        await instance.post(
          `/inquiries/update_inquiry_labels/${editingLabel._id}`,
          newLabel,
          config
        );
        successToaster("Label updated successfully!");
        fetchLabels();
      } else {
        // Create new label
        await instance.post(
          `/inquiries/create_inquiry_labels`,
          newLabel,
          config
        );
        successToaster("Label added successfully!");
      }

      // Fetch updated labels
      fetchLabels();
      handleCloseDialog();
    } catch (error) {
      errorToaster("Error saving label. Please try again.");
      console.error("Error saving label:", error);
    }
  };

  const handleOpenDeleteDialog = (label) => {
    setLabelToDelete(label);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setLabelToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (labelToDelete) {
      try {
        await instance.delete(`/inquiries/delete_inquiry_labels/${labelToDelete._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        successToaster("Label deleted successfully!");
        fetchLabels();
        // setLabels(labels.filter((label) => label.id !== labelToDelete._id));
      } catch (error) {
        errorToaster("Error deleting label. Please try again.");
        console.error("Error deleting label:", error);
      }
    }
  };

  return (
    <>
      <Dialog open={openLabel} fullWidth>
        <div className="flex justify-between items-center p-4">
          <h2 className="text-xl font-bold">Labels</h2>
          <button
            onClick={() => setOpenLabel(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            <IoMdClose size={24} />
          </button>
        </div>

        <hr className="border-t border-gray-200" />

        <div className="p-4">
          <div className="mb-4 flex items-center justify-between">
            <select
              className="p-2 border rounded-md"
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <option value="All">All</option>
              <option value="status">Status</option>
              <option value="source">Source</option>
              <option value="importance">Importance</option>
            </select>
            <button
              className="flex items-center bg-blue-500 text-white px-3 py-1 rounded-lg hover:bg-blue-600"
              onClick={() => handleOpenDialog()}
            >
              <FaPlus className="mr-2" /> Add Label
            </button>
          </div>

          <div className="flex flex-col gap-4">
            {filteredLabels.map((label) => (
              <div
                key={label._id}
                className="flex items-center justify-between p-2 rounded-lg shadow-lg"
              >
                <Chip
                  label={label.name}
                  style={{ backgroundColor: label.color, color: "#fff" }}
                />
                {!label?.default && (
                  <div className="flex items-center space-x-2">
                    <button
                      className="p-1 bg-white rounded-full hover:bg-gray-100"
                      onClick={() => handleOpenDialog(label)}
                    >
                      <FaEdit className="text-blue-500" />
                    </button>
                    <button
                      className="p-1 bg-white rounded-full hover:bg-gray-100"
                      onClick={() => handleOpenDeleteDialog(label)}
                    >
                      <FaTrash className="text-red-500" />
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Label Dialog */}
          <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <div className="p-6">
              <h2 className="text-xl font-bold mb-4">
                {editingLabel ? "Edit Label" : "Add New Label"}
              </h2>
              <input
                type="text"
                className="w-full p-2 border rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Label Name"
                value={newLabel.name}
                onChange={(e) =>
                  setNewLabel({ ...newLabel, name: e.target.value })
                }
              />
              <select
                className="w-full p-2 border rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={newLabel.type}
                onChange={(e) =>
                  setNewLabel({ ...newLabel, type: e.target.value })
                }
              >
                <option value="status">Status</option>
                <option value="source">Source</option>
                <option value="importance">Importance</option>
              </select>
              <div className="flex items-center mb-4">
                <input
                  type="color"
                  value={newLabel.color}
                  onChange={(e) =>
                    setNewLabel({ ...newLabel, color: e.target.value })
                  }
                  className="mr-2"
                />
                <span className="text-gray-700">{newLabel.color}</span>
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  className="px-4 py-1 bg-gray-200 rounded-md hover:bg-gray-300"
                  onClick={handleCloseDialog}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                  onClick={handleSaveLabel}
                >
                  {editingLabel ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </Dialog>

          {/* Confirmation Modal */}
          <ConfirmationModal
            open={deleteDialogOpen}
            onClose={handleCloseDeleteDialog}
            onConfirm={handleConfirmDelete}
            labelName={labelToDelete ? labelToDelete.name : ''}
          />
        </div>
      </Dialog>
    </>
  );
};

export default LabelManager;
