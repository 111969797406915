import { ColorRing } from "react-loader-spinner"

const Loader = (isLoading) => {
    return (
    <div className=" absolute bg-slate-200 bg-opacity-55 h-screen w-screen flex justify-center items-center">
      <ColorRing
        className='absolute'
        visible={isLoading}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
      </div>
    )
}

export default Loader;