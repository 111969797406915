import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { errorToaster, successToaster } from '../../ReactToastify';

import { useSelector } from 'react-redux';

import { instance } from '../../../utils/axios';

export const   ConfirmDeleteModal = ({ open, onClose, enquiry, fetchEnquiries }) => {

  const token = useSelector(state => state.auth.token)

  const handleDeleteEnquiry = async () => {
    try {
      // Make the API request to delete the enquiry
      await instance.delete(`/inquiries/delete_inquiry/${enquiry?._id}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add your token or authentication header if needed
        },
      });
      successToaster("Enquiry deleted successfully!")
  
      console.log('Enquiry deleted successfully');
      onClose(); // Close the modal when the enquiry is deleted successfully
      fetchEnquiries()
    } catch (error) {
      console.error('Failed to delete enquiry', error);
      errorToaster(error.response?.data?.message + " Failed to delete enquiry")
    }
  };
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-delete-title"
      aria-describedby="confirm-delete-description"
      classes={{
        paper: 'bg-white rounded-lg p-4 max-w-md w-full',
      }}
    >
      <DialogTitle id="confirm-delete-title" className="text-lg font-semibold text-gray-900">
        Confirm Deletion
      </DialogTitle>
      <DialogContent className="text-sm text-gray-600">
        Are you sure you want to delete this item? This action cannot be undone.
      </DialogContent>
      <DialogActions className="flex justify-end mt-4">
        <button onClick={onClose} className="text-gray-600 px-2 rounded-md hover:text-gray-800">
          Cancel
        </button>
        <button
          onClick={handleDeleteEnquiry}
          className="bg-red-600 px-2  rounded-md text-white hover:bg-red-700 ml-2"
        >
          Delete
        </button>
      </DialogActions>
    </Dialog>
  );
};
