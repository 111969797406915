import React, { useEffect, useState } from "react";
import {
  Button,
  MenuItem,
  Select,
  Box,
  Grid,
  TextareaAutosize
} from "@mui/material";
import { useSelector } from "react-redux";
import { IoAddOutline } from "react-icons/io5";
import LabelManager from "../modal/AddLabel";

import { errorToaster, successToaster } from "../../ReactToastify";
import { instance } from "../../../utils/axios";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
// const staticOptions = {
//   status: [
//     { label: "New", color: "#059669" },
//     { label: "In Progress", color: "#2563EB" },
//     { label: "Completed", color: "#DC2626" },
//   ],
//   source: [
//     { label: "Website", color: "#10B981" },
//     { label: "Referral", color: "#8B5CF6" },
//     { label: "Direct", color: "#FBBF24" },
//   ],
//   importance: [
//     { label: "Low", color: "#4B5563" },
//     { label: "Medium", color: "#D97706" },
//     { label: "High", color: "#DC2626" },
//   ],
// };
const EditLead = ({  enquiry, toggleDrawer , fetchEnquiries}) => {
  const [leadData, setLeadData] = useState({
    viewerName: "",
    status: "",
    source: "",
    importance: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    phone: "",
    alternatePhone: "",
    email: "",
    note: "",
    createdAt: "",
  });

  const token = useSelector((state) => state?.auth?.token);
  const [openLabel, setOpenLabel] = useState(false)

  const [errors, setErrors] = useState({});
  const [options, setOptions] = useState({
    status: [],
    source: [],
    importance: [],
  });


  const [isLoading, setIsLoading] = useState(false)


  // const [defaultLables, setDefaultLabels] = useState({
  //   status:[],
  //   importance:[],
  //   sourcee:[]
  // });


  const staticOptions  =  useSelector((state) => state?.labels?.labels);

// console.log(leadData, staticOptions, "this is data")
  useEffect(() => {
    if (enquiry) {
      setLeadData(enquiry);
    }
  }, [enquiry]);

    useEffect(() => {
    if (staticOptions) {
      // Assuming labels are fetched and available via props
      const defaultOptions = {
        status: staticOptions.filter((label) => label.type === 'status'),
        source: staticOptions.filter((label) => label.type === 'source'),
        importance: staticOptions.filter((label) => label.type === 'importance'),
      };

      setOptions(defaultOptions);
    }
  }, [staticOptions]);

//   const fetchInquiryLabels = async () => {
//     try {
//       const response = await instance.get('api/inquiries/get_inquiry_labels',    {
//         headers: {
//           authorization: `Bearer ${authToken}`,
//         },
//       });
//       if(response?.data?.data){
//         const data = response?.data?.data;

//        const importanceLables =  data.filter((data)=>data.type==="importance")
//        const statusLables =  data.filter((data)=>data.type==="status")
//        const sourceLables =  data.filter((data)=>data.type==="source")

//         const updatedLables  = {
//           status:statusLables, 
//           importance:importanceLables, 
//           source:sourceLables
//         }
// console.log(updatedLables, 'this s updated labels')
//         // setStaticOptions(updatedLables)
//         setDefaultLabels(updatedLables)
//       }
//       console.log(response)
//     } catch (error) {

//       console.error('Failed to fetch inquiry labels:', error);
//     }
//   };
//   useEffect(() => {
//    fetchInquiryLabels()
//   }, []);



  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^(?:\+91|91)?(?:\d{10}|\d{5} \d{5}|\d{5} \d{6}|\d{11}|\d{12})$/;
    return phoneRegex.test(phone.replace(/\s+/g, ''));
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    

    // Validate required fields
    if (!leadData.viewerName.trim()) {
      validationErrors.viewerName = "Viewer Name is required";
    }
    if (!leadData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(leadData.email)) {
      validationErrors.email = "Invalid email address";
    }
    if (!leadData.phone.trim()) {
      validationErrors.phone = "Phone number is required";
    } else if (!validatePhoneNumber(leadData.phone)) {
      validationErrors.phone = "Invalid phone number format";
    }

    // If there are validation errors, show toast and update error state
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      Object.values(validationErrors).forEach((error) => {
        errorToaster(error);
      });
      return;
    }

    const { _id, createdAt, ...leadDataWithoutId} = leadData;
    // console.log(("lead data ", leadData));
    
    // return

    // if (/[a-zA-Z]/.test(phone)) {
    //   setIsLoading(false);
    //   return errorToaster("Phone number cannot contain letters!"); // Return as is if it contains letters
    // }

    // if (/[a-zA-Z]/.test(alternatePhone)) {
    //   setIsLoading(false);
    //   return errorToaster("Phone number cannot contain letters!"); // Return as is if it contains letters
    // }

    // if()

    const submitData = {
      ...leadDataWithoutId,
      // phone : normalizePhoneNumber(phone),
      // alternatePhone : normalizePhoneNumber(alternatePhone)
    }

    try {
      setIsLoading(true)
      // Replace with your actual API endpoint
      const response = await instance.post(
        `/inquiries/get_inquiry_update/${enquiry?._id}`,
        submitData,
        {
          headers : {
            Authorization : `Bearer ${token}`
          }
        }
      );

      if(response?.data?.code===200){

        successToaster("Lead updated successfully!");
        console.log("Lead updated:", response.data);
        toggleDrawer();
        fetchEnquiries()
      } else{
        errorToaster("Something went wrong")
      }


      setIsLoading(false)
      // setDrawerType(''); // Close the drawer after successful update

    } catch (error) {
      setIsLoading(false)
      errorToaster(error.response?.data?.message + " something went wrong");
      console.error("Error updating lead data:", error);
    }
  };
  
  const renderTextField = (label, name, value, additionalClassName, type = 'text') => (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label} {['viewerName', 'email', 'phone'].includes(name) && <span className="text-red-500">*</span>}
      </label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={(e) => setLeadData({ ...leadData, [name]: e.target.value })}
        className={`w-full border border-gray-300 rounded-md py-2 px-3 ${additionalClassName}`}
      />
      {errors[name] && <p className="text-red-500 text-sm">{errors[name]}</p>}
    </div>
  );

    const renderDropdown = (label, name, value, type) => {
    // const selectedOption = options[type].find((opt) => opt?.name === value);
  
    return (
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
        <Select
          size="small"
          sx={{ width: '100%' }}
          name={name}
          value={value ? value : ''}
          // onChange={(e) => {
          //   const selectedOption = options[type].find((opt) => opt.name === e.target.value);
          //   setLeadData({ ...leadData, [name]: selectedOption });
          // }}
          onChange={(e) => setLeadData({ ...leadData, [name]: e.target.value })}
          renderValue={(selected) => {
            const option = options[type]?.find((opt) => opt.name?.toLowerCase() === selected?.toLowerCase());
            return (
            <Box
              sx={{
                backgroundColor: option?.color || 'transparent',
                color: 'white',
                padding: '2px 8px',
                borderRadius: '4px',
              }}
            >
              {option?.name || ''}
            </Box>
          )}}
        >
          {options[type].length > 0 ? options[type].map((option) => (
            <MenuItem
              key={option._id}
              value={option.name.toLowerCase()}
              sx={{
                backgroundColor: option.color,
                color: 'white',
                '&:hover': {
                  backgroundColor: option.color,
                  opacity: 0.8,
                },
                '&.Mui-selected': {
                  backgroundColor: option.color,
                  '&:hover': {
                    backgroundColor: option.color,
                    opacity: 0.8,
                  },
                },
              }}
            >
              {option.name}
            </MenuItem>
          ))
          : 
          <button onClick={()=>setOpenLabel(true)} className="w-full bg-brand-main text-white flex items-center justify-center p-1 "><IoAddOutline/>
            <span>Add Label</span>
          </button>

        }
        </Select>
      </div>
    );
  };



//   const renderDropdown = (label, name, value, type) => (
//     <div>
//       <label className="block text-sm font-medium text-gray-700 mb-1">
//         {label}
//       </label>
//       <Select
//         size="small"
//         sx={{ width: '100%' }}
//         name={name}
//         value={value ? value : ''}
//         onChange={(e) => setLeadData({ ...leadData, [name]: e.target.value })}
//         renderValue={(selected) => {
//           const option = staticOptions[type]?.find((opt) => opt.name.toLowerCase() === selected.toLowerCase());
//           return (
//             <Box
//               sx={{
//                 backgroundColor: option ? option.color : 'transparent',
//                 color: 'white',
//                 padding: '2px 8px',
//                 borderRadius: '4px'
//               }}
//             >
//               {selected || `Select ${label}`}
//             </Box>
//           );
//         }}
//       >
//         { staticOptions[type]?.length > 0 ?  staticOptions[type].map((option) => (
//           <MenuItem
//             key={option._id}
//             value={option.name.toLowerCase()}
//             sx={{
//               backgroundColor: option.color,
//               color: 'white',
//               '&:hover': {
//                 backgroundColor: option.color,
//                 opacity: 0.8
//               },
//               '&.Mui-selected': {
//                 backgroundColor: option.color,
//                 '&:hover': {
//                   backgroundColor: option.color,
//                   opacity: 0.8
//                 }
//               }
//             }}
//           >
//             {option.name}
//           </MenuItem>
//         )) :  <button onClick={()=>setOpenLabel(true)} className="w-full bg-brand-main text-white flex items-center justify-center p-1 "><IoAddOutline/>
// <span>Add Label</span></button>}
//       </Select>
//     </div>
//   );
  
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ maxWidth: 800, margin: "auto", px: 2 }}
    >
      <h2 className="font-semibold text-xl mb-4">Edit Lead Details</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {renderTextField("Viewer Name", "viewerName", leadData?.viewerName, 'py-[9px]')}
        </Grid>
        <Grid item xs={12} sm={6}>
  {renderDropdown("Status", "status", leadData?.status, 'status')}
</Grid>
<Grid item xs={12} sm={6}>
  {renderDropdown("Source", "source", leadData?.source, 'source')}
</Grid>
<Grid item xs={12} sm={6}>
  {renderDropdown("Importance", "importance", leadData?.importance, 'importance')}
</Grid>
        {/* <Grid item xs={12} sm={6}>
          {renderDropdown(
            "Status",
            "status",
            leadData.status,
            staticOptions.status
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderDropdown(
            "Source",
            "source",
            leadData.source,
            staticOptions.source
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderDropdown(
            "Importance",
            "importance",
            leadData.importance,
            staticOptions.importance
          )}
        </Grid> */}
        <Grid item xs={12} sm={6}>
          {/* {renderTextField("Phone", "phone", leadData?.phone)} */}

          <div className='w-full'>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Phone <span className="text-red-500">*</span>
            </label>
            <PhoneInput
              country={'ind'}
              value={leadData?.phone}
              onChange={(phone) => setLeadData({ ...leadData, phone: phone })}
              // className=" w-full"
              inputStyle={{
                width: "100%",
                height: "40px",
                borderRadius: "5px",
              }}
            />
            {errors['phone'] && <p className="text-red-500 text-sm">{errors['phone']}</p>}
          </div>



         
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* {renderTextField(
            "Alternate Phone",
            "alternatePhone",
            leadData?.alternatePhone
          )} */}
          {/* <PhoneInput
          country={'ind'}
          value={leadData?.phone}
          onChange={(phone) => setLeadData({ ...leadData, name: phone })}
          /> */}

<div className='w-full'>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Alternate Phone
            </label>
            <PhoneInput
              country={'ind'}
              value={leadData?.alternatePhone}
              onChange={(phone) => setLeadData({ ...leadData, alternatePhone: phone })}
              inputStyle={{
                width: "100%",
                height: "40px",
                borderRadius: "5px",
              }}
              
            />
            {errors['alternatePhone'] && <p className="text-red-500 text-sm">{errors['alternatePhone']}</p>}
          </div>

        </Grid>
        <Grid item xs={12}>
          {renderTextField("Email", "email", leadData?.email)}
        </Grid>
        <Grid item xs={12}>
          {renderTextField("Address", "address", leadData?.address)}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextField("City", "city", leadData?.city)}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextField("State", "state", leadData?.state)}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextField("Pincode", "pincode", leadData?.pincode)}
        </Grid>
        <Grid item xs={12}>
          <p>Note : </p>
          <TextareaAutosize
            minRows={3}
            placeholder="Note"
            name="note"
            value={leadData.note}
            onChange={(e) => setLeadData({ ...leadData, note: e.target.value })}
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "4px",
              marginBottom: "8px",
              borderRadius: "4px",
              borderColor: "#ccc",
              border: "1px solid",
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }}>
    { !isLoading ?    <Box sx={{ mt: 3 }}>
        <Button type="submit" variant="contained" color="primary" fullWidth>
           Save Changes
         </Button>
       </Box> : 
        
        <button disabled className="w-full bg-brand-400 text-white p-2 rounded-md">
       Please wait 
      </button>
        
        
        }
      </Box>


      <LabelManager openLabel={openLabel} setOpenLabel={setOpenLabel} />

    </Box>
  );
};
export default EditLead;



// import React, { useEffect, useState } from "react";
// import {
//   Button,
//   MenuItem,
//   Select,
//   Box,
//   Grid,
//   TextareaAutosize,
//   Chip,
// } from "@mui/material";
// import axios from "axios";
// import { errorToaster, successToaster } from "../../ReactToastify";
// import { useSelector } from "react-redux";
// import { baseURL } from "../../../constant";

// // const staticOptions = {
// //   status: [
// //     { label: "New", color: "#059669" },
// //     { label: "In Progress", color: "#2563EB" },
// //     { label: "Completed", color: "#DC2626" },
// //   ],
// //   source: [
// //     { label: "Website", color: "#10B981" },
// //     { label: "Referral", color: "#8B5CF6" },
// //     { label: "Direct", color: "#FBBF24" },
// //   ],
// //   importance: [
// //     { label: "Low", color: "#4B5563" },
// //     { label: "Medium", color: "#D97706" },
// //     { label: "High", color: "#DC2626" },
// //   ],
// // };

// const EditLead = ({ enquiry, toggleDrawer, fetchEnquiries }) => {
//   const labels = useSelector(state => state.labels.labels)
//   const token = useSelector(state => state.auth.token)
//   const [leadData, setLeadData] = useState({
//     viewerName: "",
//     status: {
//       label: "",
//       color: "",
//     },
//     source: {
//       label: "",
//       color: "",
//     },
//     importance: {
//       label: "",
//       color: "",
//     },
//     address: "",
//     website : '',
//     city: "",
//     state: "",
//     pincode: "",
//     phone: "",
//     alternatePhone: "",
//     email: "",
//     notes: "",
//     createdAt: "",
//   });

//   const [errors, setErrors] = useState({});
//   const [options, setOptions] = useState({
//     status: [],
//     source: [],
//     importance: [],
//   });

//   useEffect(() => {
//     if (enquiry) {
//       setLeadData(enquiry);
//     }
//   }, [enquiry]);

//   useEffect(() => {
//     if (labels) {
//       // Assuming labels are fetched and available via props
//       const defaultOptions = {
//         status: labels.filter((label) => label.type === 'status'),
//         source: labels.filter((label) => label.type === 'source'),
//         importance: labels.filter((label) => label.type === 'importance'),
//       };

//       setOptions(defaultOptions);
//     }
//   }, [labels]);

//   const validatePhoneNumber = (phone) => {
//     const phoneRegex = /^(?:\+91|91)?(?:\d{10}|\d{5} \d{5}|\d{5} \d{6}|\d{11}|\d{12})$/;
//     return phoneRegex.test(phone.replace(/\s+/g, ''));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const validationErrors = {};

//     // Validate required fields
//     if (!leadData.viewerName.trim()) {
//       validationErrors.viewerName = "Viewer Name is required";
//     }
//     if (!leadData.email.trim()) {
//       validationErrors.email = "Email is required";
//     } else if (!/\S+@\S+\.\S+/.test(leadData.email)) {
//       validationErrors.email = "Invalid email address";
//     }
//     if (!leadData.phone.trim()) {
//       validationErrors.phone = "Phone number is required";
//     } else if (!validatePhoneNumber(leadData.phone)) {
//       validationErrors.phone = "Invalid phone number format";
//     }

//     // If there are validation errors, show toast and update error state
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       Object.values(validationErrors).forEach((error) => {
//         errorToaster(error);
//       });
//       return;
//     }

//     const { _id, ...leadDataWithoutId} = leadData;

//     try {
//       // Replace with your actual API endpoint
//       const response = await axios.post(
//         `${baseURL}/inquiries/get_inquiry_update/${enquiry?._id}`,
//         leadDataWithoutId,
//         {
//           headers : {
//             Authorization : `Bearer ${token}`
//           }
//         }
//       );
//       successToaster("Lead updated successfully!");
//       console.log("Lead updated:", response.data);
//       toggleDrawer(); // Close the drawer after successful update
//       fetchEnquiries()

//     } catch (error) {
//       errorToaster(error.response?.data?.message + " something went wrong");
//       console.error("Error updating lead data:", error);
//     }
//   };

//   const renderTextField = (label, name, value, additionalClassName, type = 'text') => (
//     <div>
//       <label className="block text-sm font-medium text-gray-700 mb-1">
//         {label} {['viewerName', 'email', 'phone'].includes(name) && <span className="text-red-500">*</span>}
//       </label>
//       <input
//         type={type}
//         name={name}
//         value={value}
//         onChange={(e) => setLeadData({ ...leadData, [name]: e.target.value })}
//         className={`w-full border border-gray-300 rounded-md py-2 px-3 ${additionalClassName}`}
//       />
//       {errors[name] && <p className="text-red-500 text-sm">{errors[name]}</p>}
//     </div>
//   );

//   const renderDropdown = (label, name, value, type) => {
//     const selectedOption = options[type].find((opt) => opt?.name === value?.name);
  
//     return (
//       <div>
//         <label className="block text-sm font-medium text-gray-700 mb-1">
//           {label}
//         </label>
//         <Select
//           size="small"
//           sx={{ width: '100%' }}
//           name={name}
//           value={selectedOption || ''}
//           onChange={(e) => {
//             const selectedOption = options[type].find((opt) => opt.name === e.target.value);
//             setLeadData({ ...leadData, [name]: selectedOption });
//           }}
//           renderValue={(selected) => (
//             <Box
//               sx={{
//                 backgroundColor: selected?.color || 'transparent',
//                 color: 'white',
//                 padding: '2px 8px',
//                 borderRadius: '4px',
//               }}
//             >
//               {selected?.name || ''}
//             </Box>
//           )}
//         >
//           {options[type].map((option) => (
//             <MenuItem
//               key={option._id}
//               value={option.name}
//               sx={{
//                 backgroundColor: option.color,
//                 color: 'white',
//                 '&:hover': {
//                   backgroundColor: option.color,
//                   opacity: 0.8,
//                 },
//                 '&.Mui-selected': {
//                   backgroundColor: option.color,
//                   '&:hover': {
//                     backgroundColor: option.color,
//                     opacity: 0.8,
//                   },
//                 },
//               }}
//             >
//               {option.name}
//             </MenuItem>
//           ))}
//         </Select>
//       </div>
//     );
//   };

//   return (
//     <Box
//       component="form"
//       onSubmit={handleSubmit}
//       sx={{ maxWidth: 800, margin: "auto", px: 2 }}
//     >
//       <h2 className="font-semibold text-xl mb-4">Edit Lead Details</h2>

//       <Grid container spacing={2}>
//         <Grid item xs={12} sm={6}>
//           {renderTextField("Viewer Name", "viewerName", leadData?.viewerName, 'py-[9px]')}
//         </Grid>

//         <Grid item xs={12} sm={6}>
//           {renderDropdown("Status", "status", leadData?.status, 'status')}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderDropdown("Source", "source", leadData?.source, 'source')}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderDropdown("Importance", "importance", leadData?.importance, 'importance')}
//         </Grid>

//         {/* <Grid item xs={12} sm={6}>
//           {renderDropdown(
//             "Status",
//             "status",
//             leadData.status,
//             staticOptions.status
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderDropdown(
//             "Source",
//             "source",
//             leadData.source,
//             staticOptions.source
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderDropdown(
//             "Importance",
//             "importance",
//             leadData.importance,
//             staticOptions.importance
//           )}
//         </Grid> */}
//         <Grid item xs={12} sm={6}>
//           {renderTextField("Phone", "phone", leadData?.phone)}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderTextField(
//             "Alternate Phone",
//             "alternatePhone",
//             leadData?.alternatePhone
//           )}
//         </Grid>
//         <Grid item xs={12}>
//           {renderTextField("Email", "email", leadData?.email)}
//         </Grid>
//         <Grid item xs={12}>
//           {renderTextField("Website", "website", leadData?.website)}
//         </Grid>

//         <Grid item xs={12}>
//           {renderTextField("Address", "address", leadData?.address)}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderTextField("City", "city", leadData?.city)}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderTextField("State", "state", leadData.state)}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderTextField("Pincode", "pincode", leadData?.pincode)}
//         </Grid>
//         <Grid item xs={12}>
//           <p>Note : </p>
//           <TextareaAutosize
//             minRows={3}
//             placeholder="Note"
//             name="notes"
//             value={leadData.notes}
//             onChange={(e) => setLeadData({ ...leadData, notes: e.target.value })}
//             style={{
//               width: "100%",
//               padding: "8px",
//               marginTop: "4px",
//               marginBottom: "8px",
//               borderRadius: "4px",
//               borderColor: "#ccc",
//               border: "1px solid",
//             }}
//           />
//         </Grid>
//       </Grid>

//       <Box sx={{ mt: 3 }}>
//         <Button type="submit" variant="contained" color="primary" fullWidth>
//           Save Changes
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default EditLead;
