import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { errorToaster } from "../../../ReactToastify";
import { useSelector } from "react-redux";


export const SendWhatsappModal = ({ showWhatsapp, handleCloseWhatsappModal, dataForWhatsapp }) => {

    const [sendingMessage, setSendingMessage] = useState(false);
    const [message, setMessage] = useState('')
    const userData = useSelector(state  => state.auth.user)

    useEffect(() => {
        console.log(dataForWhatsapp);
        
        const whatsappMSG = `Dear ${dataForWhatsapp?.viewerName ? dataForWhatsapp?.viewerName : 'User'},
        \nThank you for attending our event! We truly appreciate your time and support. If you have any questions or need further information, feel free to reach out.
        \nWe look forward to staying in touch!
        \nBest regards,
IMMARSIFY`;
        const whatsappMessage =userData?.defaultMessage?.Whatsapp ? encodeURIComponent(userData?.defaultMessage?.Whatsapp) : encodeURIComponent(whatsappMSG);
        setMessage(decodeURIComponent(whatsappMessage))
    },[dataForWhatsapp])

    const handleSendWhatsappMessage = () => {
        setSendingMessage(true)
        const newPhone = normalizePhoneNumber(dataForWhatsapp?.phone)
    
        // const whatsappMessage = userData?.defaultMessage?.Whatsapp ? encodeURIComponent(userData?.defaultMessage?.Whatsapp) : encodeURIComponent(whatsappMSG);
        const url = `https://wa.me/${newPhone}?text=${message}`;
        window.open(url, "_self");
        setSendingMessage(false)
      }

      const normalizePhoneNumber = (phoneNumber) => {
        phoneNumber = phoneNumber.replace(/\s+/g, ""); // Remove all spaces
    
        if (/[a-zA-Z]/.test(phoneNumber)) {
          return errorToaster("Phone number cannot contain letters!"); // Return as is if it contains letters
        }
    
        // If the number starts with +91 or 91 and has more than 10 characters
        if (phoneNumber.length > 10) {
          if (phoneNumber.startsWith("+91")) {
            phoneNumber = phoneNumber.substring(3); // Remove +91
          } else if (phoneNumber.startsWith("91")) {
            phoneNumber = phoneNumber.substring(2); // Remove 91
          }
        }
    
        // After removing country codes, ensure the length is 10
        if (phoneNumber.length > 10) {
          phoneNumber = phoneNumber.substring(phoneNumber.length - 10); // Keep only the last 10 digits
        }
    
        // If the resulting length is exactly 10, add the country code
        if (phoneNumber.length === 10) {
          phoneNumber = "91" + phoneNumber; // Add 91 country code
        }
    
        return phoneNumber;
      };

    return (
        <Dialog
            open={showWhatsapp}
            onClose={handleCloseWhatsappModal}
            aria-labelledby="confirm-delete-title"
            aria-describedby="confirm-delete-description"
            classes={{
                paper: "bg-white rounded-lg p-4 max-w-md w-full",
            }}
        >
            <DialogTitle
                id="confirm-delete-title"
                className="text-lg font-semibold text-gray-900"
            >
                Send Message
            </DialogTitle>
            <DialogContent className="text-sm text-gray-600">
                <div className="text-gray-800">
                    <p>Message will sent to: <span className=" font-medium">{dataForWhatsapp?.phone}</span></p>
                    <textarea
                        value={message}
                        disabled={true}
                        // onChange={(e) => setMessagge(e.target.value)}
                        name=""
                        id=""
                        rows={10}
                        // className="border w-full border-black mt-4"
                        className="w-full mt-4"
                    ></textarea>
                    {/* <p>{message}</p> */}
                </div>
            </DialogContent>
            <DialogActions className="flex justify-end mt-4">
                <button
                    onClick={handleCloseWhatsappModal}
                    className="text-black bg-gray-200  px-4 py-2 rounded-md hover:text-gray-800"
                >
                    Cancel
                </button>
                <button
                    disabled={sendingMessage}
                    onClick={() => {
                        handleSendWhatsappMessage();
                        handleCloseWhatsappModal()
                    }}
                    className="bg-green-600 px-4 py-2  rounded-md text-white hover:bg-green-700 ml-2"
                >
                    {sendingMessage ? "Sending" : "Send"}
                </button>
            </DialogActions>
        </Dialog>
    );
};
