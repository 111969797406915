import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authReducer";
import labelsSlice from "./labelsSlice";



export const store = configureStore({
    reducer: {
        auth : authReducer,
        labels : labelsSlice
    }
})