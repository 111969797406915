import React, { useEffect, useState } from "react";
import { Chip } from "@mui/material";
import { useSelector } from "react-redux";
// Temporary static data (to be replaced with API call in the future)

const ViewLead = ({ leadId, enquiry }) => {
  const [leadData, setLeadData] = useState({
    viewerName: "",
    status: "",
    source: "",
    importance: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    phone: "",
    alternatePhone: "",
    email: "",
    note: "",
    experienceName: "",
    type: "",
    userId: "",
    createdAt: "",
  });
  const [options, setOptions] = useState({
    status: [],
    source: [],
    importance: [],
  });
  const staticOptions = useSelector((state) => state?.labels?.labels);

  useEffect(() => {
    setLeadData(enquiry);
  }, [enquiry]);

  useEffect(() => {
    if (staticOptions) {
      // Assuming labels are fetched and available via props
      const defaultOptions = {
        status: staticOptions.filter((label) => label.type === 'status'),
        source: staticOptions.filter((label) => label.type === 'source'),
        importance: staticOptions.filter((label) => label.type === 'importance'),
      };
      

      setOptions(defaultOptions);
    }
  }, [staticOptions]);

  return (
    <div className="mx-auto max-w-screen rounded-lg bg-white p-6 shadow-md">
      <h2 className="mb-4 text-2xl font-bold">Lead Details</h2>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Viewer Name
        </label>
        <p className="mt-1">{leadData?.viewerName}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Status
        </label>
        <div className="mt-1">
          {" "}
          {enquiry?.status ? (
            <Chip
              label={enquiry?.status}
              sx={{
                backgroundColor: options?.status?.find(
                  (status) =>
                    status?.name?.toLowerCase() ===
                    enquiry?.status?.toLowerCase()
                )?.color,
                color: "#fff",
              }}
            />
          ) : (
            "-"
          )}
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Source
        </label>
        <div className="mt-1">
          {" "}
          {enquiry?.source ? (
            <Chip
              label={enquiry?.source}
              sx={{
                backgroundColor: options['source']?.find(
                  (source) =>
                    source?.name?.toLowerCase() ===
                    enquiry?.source?.toLowerCase()
                )?.color,
                color: "#fff",
              }}
            />
          ) : (
            "-"
          )}
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Importance
        </label>
        <div className="mt-1">
          {" "}
          {enquiry?.importance ? (
            <Chip
              label={enquiry?.importance}
              sx={{
                backgroundColor: options?.importance.find(
                  (importance) =>
                    importance?.name?.toLowerCase() ===
                    enquiry?.importance?.toLowerCase()
                )?.color,
                color: "#fff",
              }}
            />
          ) : (
            "-"
          )}
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Phone</label>
        <p className="mt-1">{leadData?.phone || "-"}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Alternate Phone
        </label>
        <p className="mt-1">{leadData?.alternatePhone || "-"}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Email</label>
        <p className="mt-1">{leadData?.email || "-"}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Website
        </label>
        <p className="mt-1">{leadData?.website || "-"}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Address
        </label>
        <p className="mt-1">{leadData?.address || "-"}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">City</label>
        <p className="mt-1">{leadData?.city || "-"}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">State</label>
        <p className="mt-1">{leadData?.state || "-"}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Pincode
        </label>
        <p className="mt-1">{leadData?.pincode || "-"}</p>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Note</label>
        <p className="mt-1">{leadData?.note || "-"}</p>
      </div>
      {/* <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Experience Name</label>
        <p className="mt-1">{leadData.experienceName}</p>
      </div> */}
      {/* <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Type</label>
        <p className="mt-1">{leadData.type}</p>
      </div> */}
      {/* <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">User ID</label>
        <p className="mt-1">{leadData.userId}</p>
      </div> */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Created At
        </label>
        <p className="mt-1">{new Date(leadData?.createdAt).toLocaleString()}</p>
      </div>
    </div>
  );
};
export default ViewLead;

// import React, { useEffect, useState } from "react";
// import { Chip } from "@mui/material";
// import { useSelector } from "react-redux";

// // Temporary static data (to be replaced with API call in the future)
// // const staticOptions = {
// //   status: [
// //     { label: "New", color: "#059669" },
// //     { label: "In Progress", color: "#2563EB" },
// //     { label: "Completed", color: "#DC2626" },
// //   ],
// //   source: [
// //     { label: "Website", color: "#10B981" },
// //     { label: "Referral", color: "#8B5CF6" },
// //     { label: "Direct", color: "#FBBF24" },
// //   ],
// //   importance: [
// //     { label: "Low", color: "#4B5563" },
// //     { label: "Medium", color: "#D97706" },
// //     { label: "High", color: "#DC2626" },
// //   ],
// // };

// const ViewLead = ({ enquiry }) => {

//   const labels = useSelector(state => state.labels.labels)
//   const [leadData, setLeadData] = useState({
//     viewerName: "",
//     status: "",
//     source: "",
//     importance: "",
//     address: "",
//     city: "",
//     state: "",
//     pincode: "",
//     phone: "",
//     alternatePhone: "",
//     email: "",
//     notes: "",
//     experienceName: "",
//     type: "",
//     userId: "",
//     createdAt: "",
//   });

//   useEffect(() =>{
//     setLeadData(enquiry);
//   },[enquiry])

//   // useEffect(() => {
//   //   // Fetch lead data from API
//   //   // This is a placeholder for the actual API call
//   //   const fetchLeadData = async () => {
//   //     try {
//   //       // const response = await fetch(`/api/leads/${leadId}`);
//   //       // const data = await response.json();
//   //       // setLeadData(data);

//   //       // Simulating API response with dummy data
//   //       setLeadData({
//   //         viewerName: "John Doe",
//   //         status: "New",
//   //         source: "Website",
//   //         importance: "Medium",
//   //         address: "123 Main St",
//   //         city: "Anytown",
//   //         state: "CA",
//   //         pincode: "12345",
//   //         phone: "555-1234",
//   //         alternatePhone: "555-5678",
//   //         email: "john@example.com",
//   //         notes: "Interested in our premium package",
//   //         experienceName: "Product Demo",
//   //         type: "B2B",
//   //         userId: "user123",
//   //         createdAt: "2024-08-16T10:00:00Z",
//   //       });
//   //     } catch (error) {
//   //       console.error("Error fetching lead data:", error);
//   //     }
//   //   };

//   //   fetchLeadData();
//   // }, [leadId]);

//   // Placeholder for future API call to fetch options
//   // useEffect(() => {
//   //   // Fetch status, source, and importance options from API
//   //   // This is a placeholder for the actual API call
//   //   // const fetchOptions = async () => {
//   //   //   try {
//   //   //     // const response = await fetch('/api/options');
//   //   //     // const data = await response.json();
//   //   //     // Update staticOptions with the fetched data
//   //   //   } catch (error) {
//   //   //     console.error("Error fetching options:", error);
//   //   //   }
//   //   // };

//   //   fetchOptions();
//   // }, []);

//   const renderChip = (value, options) => {
//     // const option = options.find((opt) => opt.name === value.name);
//     return <Chip
//             label={value?.name}
//             sx={{
//               backgroundColor: value?.color,
//               color: "white",
//             }}
//           />
//   };

//   return (
//     <div className="mx-auto max-w-screen rounded-lg bg-white p-6 shadow-md">
//       <h2 className="mb-4 text-2xl font-bold">Lead Details</h2>

//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">Viewer Name</label>
//         <p className="mt-1">{leadData?.viewerName}</p>
//       </div>

//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">Status</label>
//         <div className="mt-1">{renderChip(leadData?.status, labels?.status)}</div>
//       </div>

//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">Source</label>
//         <div className="mt-1">{renderChip(leadData?.source, labels?.source)}</div>
//       </div>

//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">Importance</label>
//         <div className="mt-1">{renderChip(leadData?.importance, labels?.importance)}</div>
//       </div>

//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">Phone</label>
//         <p className="mt-1">{leadData?.phone}</p>
//       </div>

//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">Alternate Phone</label>
//         <p className="mt-1">{leadData?.alternatePhone}</p>
//       </div>

//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">Email</label>
//         <p className="mt-1">{leadData?.email}</p>
//       </div>
//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">Website</label>
//         <p className="mt-1">{leadData?.website}</p>
//       </div>

//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">Address</label>
//         <p className="mt-1">{leadData?.address}</p>
//       </div>

//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">City</label>
//         <p className="mt-1">{leadData?.city}</p>
//       </div>

//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">State</label>
//         <p className="mt-1">{leadData?.state}</p>
//       </div>

//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">Pincode</label>
//         <p className="mt-1">{leadData?.pincode}</p>
//       </div>

//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">Notes</label>
//         <p className="mt-1">{leadData?.notes}</p>
//       </div>

//       {/* <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">Experience Name</label>
//         <p className="mt-1">{leadData.experienceName}</p>
//       </div> */}

//       {/* <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">Type</label>
//         <p className="mt-1">{leadData.type}</p>
//       </div> */}

//       {/* <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">User ID</label>
//         <p className="mt-1">{leadData.userId}</p>
//       </div> */}

//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700">Created At</label>
//         <p className="mt-1">{new Date(leadData?.createdAt).toLocaleString()}</p>
//       </div>
//     </div>
//   );
// };

// export default ViewLead;
