import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import { useDispatch } from "react-redux";


const HamburgerMenu = ({ handleSignOut, isOpen, setIsOpen, setIsModalOpen, handleNavigateToHome, handleViewEnquiries }) => {
  // const [isOpen, setIsOpen] = useState(false);
  // const [openSignOutModal, setOpenSignOutModal] = useState(false);
  // const navigate = useNavigate();
 

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // const navToHome = () => {
  //   setIsOpen(false);
  //   navigate("/"); // Adjust this path based on your routing
  // }

  // const handleViewEnquiries = () => {
  //   setIsOpen(false);
  //   navigate("/enquiries"); // Adjust this path based on your routing
  // };

//   const handleConfirmSignOut = () => {
//     setIsOpen(false);
//     setOpenSignOutModal(false);
//   };
// const handleOpenSignOutModal = () => {
//     setOpenSignOutModal(true)
// }

//   const handleCloseSignoutModal = () => {
//     setOpenSignOutModal(false);
//   };

  // const handleSignOut = () => {
  //   dispatch(signOut());
  //   // localStorage.removeItem('immersify')
  //   navigate('/')
  // };

  return (
    <div className="relative z-40 lg:hidden">
      <button onClick={toggleMenu} className=" focus:outline-none text-black">
        <FontAwesomeIcon icon={faBars} size="2xl" />
      </button>

      {/* Sliding Menu */}
      <div
        className={`fixed top-0 right-0 w-10/12 sm:w-7/12 md:w-6/12 lg:w-3/12 h-full bg-slate-200 text-black border-l-[1px] border-gray-100 transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out`}
      >
        <div className="flex justify-between items-center p-4 border-b border-gray-700 text-lg">
          <span>Menu</span>
          <button onClick={toggleMenu} className="focus:outline-none">
            <FontAwesomeIcon icon={faTimes} size="2xl" />
          </button>
        </div>
        <div className="flex flex-col items-start justify-center p-4 sm:p-8 space-y-6 text-xl">
          <button
            onClick={handleNavigateToHome}
            className="text-left  focus:outline-none"
          >
            Home
          </button>
          <button
            onClick={handleViewEnquiries}
            className="text-left focus:outline-none"
          >
            View Enquiries
          </button>
          <button
            onClick={handleSignOut}
            className="text-left focus:outline-none text-white px-4 py-2 bg-red-600 rounded-lg"
          >
            Sign Out
          </button>
        </div>
      </div>
      {/* <Dialog
        open={openSignOutModal}
        onClose={handleCloseSignoutModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to sign out of your account?"}
        </DialogTitle>
        <DialogActions>
          <button onClick={handleCloseSignoutModal}
          className=" font-medium mr-4"
          >Cancel</button>
          <button onClick={handleSignOut} autoFocus 
          className="border border-red-500 bg-red-500 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-red-600 focus:outline-none focus:shadow-outline">
            Sign Out
          </button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default HamburgerMenu;
