import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import HamburgerMenu from "./Hamburger";
import logo from "../../assets/logoImmersify.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut } from "../../redux/authReducer";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const Navbar = ({ isAuthenticated }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!isAuthenticated) return null; // Only render if authenticated

  const handleNavigateToHome = () => {
    navigate("/");
    setIsOpen(false)
  };

  const handleSignOut = () => {
    setIsModalOpen(true); // Show the confirmation modal
    setIsOpen(false)
  };

  const confirmSignOut = () => {
    dispatch(signOut());
    // localStorage.removeItem('immersify')
    navigate('/');
    setIsOpen(false)
    setIsModalOpen(false); // Close the modal after sign out
  };

  const cancelSignOut = () => {
    setIsModalOpen(false); // Close the modal without signing out
  };

  const handleViewEnquiries = () => {
    setIsOpen(false);
    navigate("/enquiries"); // Adjust this path based on your routing
  };

  return (
    <>
    <nav className="fixed z-50 w-full h-20 bg-slate-100 p-4 flex justify-between items-center px-2 lg:px-12 shadow-slate-300 shadow-lg">
      <div className="text-white text-xl">
        <img
          src={logo}
          alt="immersify"
          onClick={handleNavigateToHome}
          className="h-12 mix-blend-multiply cursor-pointer  "
        />
      </div>
      <div className="hidden lg:block text-black">
        <div className="flex items-center space-x-4">
          <button
            onClick={handleNavigateToHome}
            className=" hover:text-white-500 transition duration-300"
          >
            Home
          </button>
          <button
            onClick={handleViewEnquiries}
            className=" hover:text-white-500 transition duration-300"
          >
            Enquiries
          </button>
          <button
          type="button"
            onClick={handleSignOut}
            className=" hover:text-white-500 transition duration-300 text-white px-4 py-2 bg-red-600 rounded-lg"
          >
            Sign Out
          </button>
        </div>
      </div>
      <HamburgerMenu handleSignOut={handleSignOut} isOpen={isOpen} setIsOpen={setIsOpen} handleNavigateToHome={handleNavigateToHome} handleViewEnquiries={handleViewEnquiries} setIsModalOpen={setIsModalOpen} />
    </nav>
    <Dialog
        open={isModalOpen}
        onClose={cancelSignOut}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to sign out of your account?"}
        </DialogTitle>
        <DialogActions>
          <button onClick={cancelSignOut}
          className=" font-medium mr-4"
          >Cancel</button>
          <button onClick={confirmSignOut} autoFocus 
          className="border border-red-500 bg-red-500 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-red-600 focus:outline-none focus:shadow-outline">
            Sign Out
          </button>
        </DialogActions>
      </Dialog>
  </>
  );
};

export default Navbar;
