import React, { useEffect, useState } from "react";

// import Dropdown from "./Dropdown";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { Chip, Pagination, IconButton, Button } from "@mui/material";
import ActionMenu from "./ActionMenu";
import LabelManager from "./modal/AddLabel";
import {
  FaPhone,
  FaEnvelope,
  FaWhatsapp,
  FaShareAlt,
  FaSave,
} from "react-icons/fa";

import { setLabels } from "../../redux/labelsSlice";
import VCard from "vcard-creator";
import { errorToaster } from "../ReactToastify";
import { WhatsappModals } from "./WhatsappModals";
// import SendToWhatsapp from "./SendToWhatsapp";
import { instance } from "../../utils/axios";
import ExportToCSVModal from "./modal/ExportToCSVModal";

const Enquiries = () => {
  const [enquiries, setEnquiries] = useState([]);
  // const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [openLabel, setOpenLabel] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setSelectedValue] = useState(5);
  const [loading, setLoading] = useState(false);
  const [showExportCsvModal, setShowExportCsvModal] = useState(false)
  // const [open, setOpen] = useState(false);
  // const [selectedEnquriy, setSelectedEnquiry] = useState(null);
  const [openWhatsappModal, setOpenWhatsappModal] = useState(false);

  const labels = useSelector((state) => state.labels.labels);
  const userData = useSelector(state => state.auth.user)
  // const [labels, setLabels] = useState([
  //   { _id: 1, name: "Lead", type: "importance", color: "#FF0000", default : true },
  //   { _id: 2, name: "Important", type: "source", color: "#00FF00", default : true },
  //   { _id: 3, name: "Follow Up", type: "status", color: "#0000FF", default : true },
  // ]);

  // const handleOpen = () => setOpen(true);

  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    fetchLabels();
  }, [token]);

  useEffect(() => {
    fetchEnquiries();
  }, [searchParam, currentPage, itemsPerPage]);

  const fetchLabels = async () => {
    try {
      const response = await instance.get(
        `/inquiries/get_inquiry_labels`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response of labels ", response);

      // setLabels(prev => [...prev, ...response.data?.data]);
      // console.log("response of labels ", response?.data?.data);

      dispatch(setLabels(response.data?.data || []));
    } catch (error) {
      console.error("Failed to fetch labels", error);
    }
  };

  const fetchEnquiries = async () => {
    try {
      const response = await instance.get(
        `/inquiries/get_exp_inquiry/OCR`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: currentPage,
            limit: itemsPerPage,
            search: searchParam,
          },
        }
      );
      setTotalPages(response.data?.data?.totalPages);
      setEnquiries(response.data?.data?.data);
      // setTotalCount(response.data?.data?.totalCount);
    } catch (error) {
      console.error("Failed to fetch enquiries", error);
    }
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearch(inputValue);
    if (!inputValue || inputValue.trim() === "") {
      setSearchParam("");
    } else {
      setSearchParam(inputValue.trim());
    }
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleChanges = (event, value) => {
    setCurrentPage(value);
  };

  // const handleCreateLabel = (newLabel) => {
  //   setLabels([...labels, newLabel]);
  // };

  // const handleEnquiryClick = (id) => {
  //   navigate(`/enquiries/${id}`);
  // };

  const handlePhone = (phone) => {
    window.location.href = `tel:${phone}`;
  };

  const handleEmail = (enquiry) => {
    if (!enquiry?.email) {
      return errorToaster("Email not available!");
    }
    const subject = `Immarsify Enquiry`;
    const body = `Dear ${enquiry?.viewerName},
\nThank you for attending our event! We truly appreciate your time and support. If you have any questions or need further information, feel free to reach out.
\nWe look forward to staying in touch!
\nBest regards,
IMMARSIFY`;
    window.location.href = `mailto:${enquiry?.email}?subject=${subject}&body=${body}`;
  };

  const handleWhatsApp = (enquiry) => {
    // setSelectedEnquiry(enquiry)
    // setOpenWhatsappModal(true)
    // const message = encodeURIComponent(
    //   `Hello ${name}, I'm contacting you regarding your enquiry.`
    // );
    // window.open(`https://wa.me/${phone}?text=${message}`, "_blank");

    const newPhone = normalizePhoneNumber(enquiry?.phone)

    const whatsappMSG = `Dear ${enquiry?.viewerName},
\nThank you for attending our event! We truly appreciate your time and support. If you have any questions or need further information, feel free to reach out.
\nWe look forward to staying in touch!
\nBest regards,
IMMARSIFY`;
    const whatsappMessage = userData?.defaultMessage?.Whatsapp ? encodeURIComponent(userData?.defaultMessage?.Whatsapp) : encodeURIComponent(whatsappMSG);
    const url = `https://wa.me/${newPhone}?text=${whatsappMessage}`;
    window.open(url, "_self");
  };


  const normalizePhoneNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.replace(/\s+/g, ''); // Remove all spaces

    if (/[a-zA-Z]/.test(phoneNumber)) {
      return errorToaster("Phone number cannot contain letters!"); // Return as is if it contains letters
    }

    // If the number starts with +91 or 91 and has more than 10 characters
    if (phoneNumber.length > 10) {
      if (phoneNumber.startsWith('+91')) {
        phoneNumber = phoneNumber.substring(3); // Remove +91
      } else if (phoneNumber.startsWith('91')) {
        phoneNumber = phoneNumber.substring(2); // Remove 91
      }
    }

    // After removing country codes, ensure the length is 10
    if (phoneNumber.length > 10) {
      phoneNumber = phoneNumber.substring(phoneNumber.length - 10); // Keep only the last 10 digits
    }

    // If the resulting length is exactly 10, add the country code
    if (phoneNumber.length === 10) {
      phoneNumber = '91' + phoneNumber; // Add 91 country code
    }

    return phoneNumber;
  }

  const handleSaveContact = (enquiry) => {
    // Create a new vCard
    const myVCard = new VCard();

    // Add contact information
    myVCard
      .addName(enquiry.viewerName)
      .addPhoneNumber(enquiry.phone, "MOBILE")
      .addEmail(enquiry.email)
      .addCompany(enquiry.company || "")
      .addAddress(
        "",
        "",
        enquiry.address,
        enquiry.city,
        enquiry.state,
        enquiry.pincode,
        "WORK"
      )
      .addURL(enquiry.website || "");

    // Generate vCard as string
    const vCardString = myVCard.toString();

    // Create a Blob with the vCard data
    const blob = new Blob([vCardString], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element and trigger download
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", `${enquiry.viewerName}.vcf`);
    tempLink.click();

    // Clean up
    window.URL.revokeObjectURL(url);
  };

  const handleShare = async (enquiry) => {
    const shareData = {
      title: `Contact: ${enquiry?.viewerName}`,
      text: `Name: ${enquiry?.viewerName}\nPhone: ${enquiry?.phone}\nEmail: ${enquiry?.email}`,
      // url: window.location.href
    };

    // First, check if the browser supports sharing
    if (navigator.share) {
      try {
        // Ask for user confirmation before attempting to share
        const userConfirmed = await showConfirmDialog(
          "Would you like to share this contact?"
        );

        if (userConfirmed) {
          await navigator.share(shareData);
          console.log("Successfully shared");
        } else {
          console.log("User cancelled sharing");
        }
      } catch (error) {
        console.error("Error sharing:", error);
        if (error.name === "AbortError") {
          console.log("Sharing was aborted");
        } else if (error.name === "NotAllowedError") {
          console.log("Permission to share was denied");
        }
        // Fallback to alternative sharing method
        fallbackShare(enquiry);
      }
    } else {
      console.log("Web Share API not supported");
      fallbackShare(enquiry);
    }
  };

  // Helper function to show a confirmation dialog
  const showConfirmDialog = (message) => {
    return new Promise((resolve) => {
      // You can replace this with a custom modal if you prefer
      const result = window.confirm(message);
      resolve(result);
    });
  };

  const fallbackShare = (enquiry) => {
    // Create VCF content using VCard library for consistency
    const myVCard = new VCard();
    myVCard
      .addName(enquiry.viewerName)
      .addPhoneNumber(enquiry.phone, "MOBILE")
      .addEmail(enquiry.email);

    const vcfContent = myVCard.toString();
    const blob = new Blob([vcfContent], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${enquiry.viewerName}.vcf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    errorToaster("VCF file downloaded. You can now share this file.");

    // Optionally, also copy to clipboard
    navigator.clipboard
      .writeText(
        `Name: ${enquiry.viewerName}\nPhone: ${enquiry.phone}\nEmail: ${enquiry.email}`
      )
      .then(() => console.log("Text copied to clipboard"))
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  

  // const createVCFContent = (enquiry) => {
  //   const myVCard = new VCard();
  //   myVCard
  //     .addName(enquiry.viewerName)
  //     .addPhoneNumber(enquiry.phone, 'MOBILE')
  //     .addEmail(enquiry.email);
  //   return myVCard.toString();
  // };

  //   const handleSaveContact = (enquiry) => {
  //     const vCard = `BEGIN:VCARD
  // VERSION:3.0
  // FN:${enquiry.viewerName}
  // TEL:${enquiry.phone}
  // EMAIL:${enquiry.email}
  // END:VCARD`;

  //     const blob = new Blob([vCard], { type: "text/vcard;charset=utf-8" });
  //     const url = URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = `${enquiry.viewerName}.vcf`;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   };

  return (
    <div className="pt-24">
      <div className="bg-slate-100 p-2">
        <header className="flex w-full flex-col justify-center gap-2 py-4">
          <div className="relative flex items-center justify-between">
            <div className="text-md font-bold text-navy-700">Enquiries</div>

            <div className="flex items-center justify-between gap-4">
              {/* <SendToWhatsapp /> */}
              <button
                className="text-md rounded-md bg-blue-600 px-4 py-2 text-center font-semibold text-white"
                onClick={() => setOpenLabel(true)}
              >
                Manage Labels
              </button>
            </div>
          </div>
          <div className="relative flex flex-col sm:flex-row w-full items-stretch gap-2 justify-between py-1">
            <input
              className=" rounded-lg border-2 p-2.5 text-sm outline-none md:w-4/12"
              type="text"
              value={search}
              onChange={handleSearch}
              placeholder="Search"
            />
            <div className="flex items-center justify-between gap-4">
              <button onClick={() => setShowExportCsvModal(true)} className=" px-4 py-2 rounded-lg bg-green-600 hover:bg-green-700 text-white">
                Export CSV
              </button>
              <div className="flex items-center justify-end">
                <p className="text-sm">List per page:</p>
                <select
                  className="ml-2 block h-8 w-12 rounded-md border bg-gray-200 shadow-sm outline-none"
                  placeholder="List"
                  value={itemsPerPage}
                  onChange={handleChange}
                >
                  {[...Array(10).keys()].map((_, index) => (
                    <option key={index} value={(index + 1) * 5}>
                      {(index + 1) * 5}
                    </option>
                  ))}
                </select>
              </div>
            </div>

          </div>
        </header>

        <div className="overflow-x-scroll xl:overflow-x-auto min-h-screen">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Contact
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Phone
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Email
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Label
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200 bg-white">
              {loading ? (
                [...Array(itemsPerPage)].map((_, index) => (
                  <tr key={index}>
                    <td
                      className="whitespace-nowrap px-6 py-4"
                      style={{ width: "25%" }}
                    >
                      <div role="status" className="animate-pulse">
                        <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                      </div>
                    </td>
                    <td
                      className="whitespace-nowrap px-6 py-4"
                      style={{ width: "25%" }}
                    >
                      <div role="status" className="animate-pulse">
                        <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                      </div>
                    </td>
                    <td
                      className="whitespace-nowrap px-6 py-4"
                      style={{ width: "25%" }}
                    >
                      <div role="status" className="animate-pulse">
                        <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                      </div>
                    </td>
                    <td
                      className="whitespace-nowrap px-6 py-4"
                      style={{ width: "25%" }}
                    >
                      <div role="status" className="animate-pulse">
                        <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : enquiries.length > 0 ? (
                enquiries.map((item) => {
                  if (item?.archive) {
                    return null;
                  }
                  let statusData;
                  let importanceData;

                  if (item?.status) {
                    statusData = labels.find(
                      (lab) =>
                        lab?.name.toLowerCase() === item?.status?.toLowerCase()
                    );
                  }

                  if (item?.importance) {
                    importanceData = labels.find(
                      (lab) =>
                        lab?.name.toLowerCase() ===
                        item?.importance?.toLowerCase()
                    );
                  }

                  // console.log("Importance data: ", importanceData, statusData );

                  return (
                    <tr key={item._id}>
                      <td
                        className="whitespace-nowrap px-6 py-4"
                        style={{ width: "25%" }}
                      >
                        <div className="flex items-center space-x-4">
                          <div className="text-sm font-medium text-gray-900">
                            {item?.viewerName}
                          </div>
                        </div>
                      </td>
                      <td
                        className="whitespace-nowrap px-6 py-4"
                        style={{ width: "25%" }}
                      >
                        <div className="flex space-x-2">
                          <IconButton
                            onClick={() => handlePhone(item?.phone)}
                            aria-label="Call"
                            sx={{
                              color: "#4CAF50", // Green for Call
                              "&:hover": {
                                color: "#388E3C", // Darker green on hover
                              },
                            }}
                            title="Call"
                          >
                            <FaPhone />
                          </IconButton>
                          <IconButton
                            onClick={() => handleEmail(item)}
                            aria-label="Email"
                            sx={{
                              color: "#0077B6", // Blue for Email
                              "&:hover": {
                                color: "#005A9E", // Darker blue on hover
                              },
                            }}
                            title="Mail"
                          >
                            <FaEnvelope className=' text-3xl' />
                          </IconButton>
                          <IconButton
                            onClick={() => handleWhatsApp(item)}
                            aria-label="WhatsApp"
                            sx={{
                              color: "#25D366", // Green for WhatsApp
                              "&:hover": {
                                color: "#1AA051", // Darker green on hover
                              },
                            }}
                            title="Whatsapp"
                          >
                            <FaWhatsapp className=' text-3xl' />
                          </IconButton>
                          <IconButton
                            onClick={() => handleShare(item)}
                            aria-label="Share"
                            sx={{
                              color: "#6c757d", // Gray for Share
                              "&:hover": {
                                color: "#495057", // Darker gray on hover
                              },
                            }}
                            title="Share Contact"
                          >
                            <FaShareAlt />
                          </IconButton>
                          <IconButton
                            onClick={() => handleSaveContact(item)}
                            aria-label="Save Contact"
                            sx={{
                              color: "#FF9800", // Orange for Save Contact
                              "&:hover": {
                                color: "#F57C00", // Darker orange on hover
                              },
                            }}
                            title="Save Contact"
                          >
                            <FaSave />
                          </IconButton>
                        </div>
                      </td>
                      <td
                        className="whitespace-nowrap px-6 py-4"
                        style={{ width: "25%" }}
                      >
                        <div className="text-sm text-gray-900">
                          <Chip
                            size="small"
                            label={item?.status || "No Status"}
                            sx={{
                              backgroundColor: statusData?.color || "#cccccc",
                              color: "white",
                            }}
                          />
                        </div>
                      </td>
                      <td
                        className="whitespace-nowrap px-6 py-4"
                        style={{ width: "25%" }}
                      >
                        <div className="text-sm text-gray-900">
                          {item?.phone}
                        </div>
                      </td>
                      <td
                        className="whitespace-nowrap px-6 py-4"
                        style={{ width: "25%" }}
                      >
                        <div className="text-sm text-gray-900">
                          {item?.email}
                        </div>
                      </td>
                      <td
                        className="mx-auto whitespace-nowrap px-6 py-4"
                        style={{ width: "25%" }}
                      >
                        <Chip
                          size="small"
                          label={item?.importance || "No Label"}
                          sx={{
                            backgroundColor: importanceData?.color || "#cccccc",
                            color: "white",
                          }}
                        />
                        {/* <Dropdown labels={labels} /> */}
                      </td>
                      <td
                        className="mx-auto whitespace-nowrap px-6 py-4"
                        style={{ width: "25%" }}
                      >
                        <ActionMenu
                          enquiry={item}
                          labels={labels}
                          fetchEnquiries={fetchEnquiries}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7" className="py-4 text-center">
                    <p className="bg-blue-100 px-10 py-5 text-sm text-blue-600">
                      Data Not Found!
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <LabelManager
            openLabel={openLabel}
            setOpenLabel={setOpenLabel}
            fetchLabels={fetchLabels}
          />
          <WhatsappModals
            number={"023480238432"}
            onClose={() => setOpenWhatsappModal(false)}
            open={openWhatsappModal}
          // selectedEnquriy={selectedEnquriy}
          />
          <ExportToCSVModal
            showExportCsvModal={showExportCsvModal}
            setShowExportCsvModal={setShowExportCsvModal}

          />
          <div className="my-5 flex w-full items-center justify-end text-sm">
            <Pagination
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={handleChanges}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquiries;
