import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useSelector } from "react-redux";
// import axios from "axios";
// import { baseURL } from "../../constant";
import { errorToaster, successToaster } from "../ReactToastify";
import { instance } from "../../utils/axios";
export const WhatsappModals = ({ open, onClose, selectedEnquriy }) => {
  const [message, setMessagge] =
    useState(`Thank you for attending our event! We truly appreciate your time and support. If you have any questions or need further information, feel free to reach out.
We look forward to staying in touch!`);
  const [sendingMessage, setSendingMessage] = useState(false);
  const userToken = useSelector((state) => state.auth.token);
  const sendWhatsappMessage = async () => {
    try {
      setSendingMessage(true);
      const response = await instance.post(
        `/reseller/send_msg`,
        {
          message: message,
          phoneNumber: selectedEnquriy?.phone,
          username: selectedEnquriy?.viewerName,
        },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        onClose();
        successToaster("Messege sent", 1000);
        // dispatch(SET_USERDATA(response?.data?.data?.value));
      } else {
        errorToaster("Something went wrong", 1000);
      }
      setSendingMessage(false);
    } catch (error) {
      setSendingMessage(false);
      console.log(error);
      errorToaster("Failed to update profile", 1000);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-delete-title"
      aria-describedby="confirm-delete-description"
      classes={{
        paper: "bg-white rounded-lg p-4 max-w-md w-full",
      }}
    >
      <DialogTitle
        id="confirm-delete-title"
        className="text-lg font-semibold text-gray-900"
      >
        Send Messagge
      </DialogTitle>
      <DialogContent className="text-sm text-gray-600">
        <div className="text-gray-800">
          <p>Message will sent to: {selectedEnquriy?.phone}</p>
          <textarea
            value={message}
            onChange={(e) => setMessagge(e.target.value)}
            name=""
            id=""
            rows={5}
            className="border w-full border-black mt-4"
          ></textarea>
        </div>
      </DialogContent>
      <DialogActions className="flex justify-end mt-4">
        <button
          onClick={onClose}
          className="text-black bg-gray-200  px-2 py-1 rounded-md hover:text-gray-800"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            sendWhatsappMessage();
          }}
          className="bg-green-600 px-2 py-1  rounded-md text-white hover:bg-green-700 ml-2"
        >
          {sendingMessage ? "Sending" : "Send"}
        </button>
      </DialogActions>
    </Dialog>
  );
};
