import { createSlice } from '@reduxjs/toolkit';
const defaultLabels = [
  { _id: 1, name: "Lead", type: "importance", color: "#FF0000", default : true },
  { _id: 2, name: "Facebook", type: "source", color: "#00FF00", default : true },
  { _id: 3, name: "Check", type: "status", color: "#0000FF", default : true },
]

const initialState = {
  labels: [],
};

const labelsSlice = createSlice({
  name: 'labels',
  initialState,
  reducers: {
    setLabels: (state, action) => {
      state.labels = [...defaultLabels ,...action.payload];
    },
    addLabel: (state, action) => {
      state.labels.push(action.payload);
    },
    updateLabel: (state, action) => {
      const index = state.labels.findIndex(label => label._id === action.payload._id);
      if (index !== -1) {
        state.labels[index] = action.payload;
      }
    },
    removeLabel: (state, action) => {
      state.labels = state.labels.filter(label => label._id !== action.payload);
    },
  },
});

export const { setLabels, addLabel, updateLabel, removeLabel } = labelsSlice.actions;

export default labelsSlice.reducer;
