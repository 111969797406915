import React, { useEffect, useRef, useState } from "react";
import Tesseract from "tesseract.js";
import { successToaster } from "../../ReactToastify";

const OCR2 = ({ setWords, setShowLoader, setFormData, imageBack, imageFront, setImageBack, setImageFront }) => {
  
  const [isLoading, setIsLoading] = useState(false);

  const [currentSide, setCurrentSide] = useState("");
  // const [ocrError, setOcrError] = useState("");
  // const [isCropping, setIsCropping] = useState(false);
  // const [crop, setCrop] = useState({ x: 0, y: 0 });
  // const [zoom, setZoom] = useState(1);
  // const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [progress, setProgress] = useState(0); // State to track OCR progress

  const [extractedDataFront, setExtractedDataFront] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    website: "",
  });
  const [ocrImageSrc, setOcrImageSrc] = useState(null);
  const [extractedDataBack, setExtractedDataBack] = useState({});
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [cameraError, setCameraError] = useState(null);
  const [showOptions, setShowOptions] = useState(false);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [currentStream, setCurrentStream] = useState(null);
  const [croppedImageSrc, setCroppedImageSrc] = useState(null); // State for cropped image

  const cv = window.cv;
  // console.log("devices", devices);
  const nameRegex = /([A-Z][a-z]+\s[A-Z][a-z]+)/;
  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
  // const phoneRegex = /(\+91\s?\d{10}|\+91\s?\d{5}\s?\d{5}|\d{10}|\+91\s?\d{5}\s?\d{5}|\d{5}\s?\d{5})/;
  const phoneRegex =
    /(\+91\s?\d{10}|\+91\s?\d{5}\s?\d{5}|\d{10}|\+91\s?\d{5}\s?\d{5}|\d{5}\s?\d{5})/g;

  const addressRegex1 =
    /(\d+\s*[A-Za-z\s]+),?\s*([A-Za-z\s]+),?\s*([A-Za-z\s]+),?\s*(\d{6})?/;
  const addressRegex2 = /(\d+\s*[A-Za-z\s]+)\s*[-,]\s*(\d{6})?/;
  const addressRegex3 = /(\d+\s*[A-Za-z\s]+)(?:\s*[-,]\s*(\d+))?/;
  const addressRegex4 =
    /(\d+\/\d+|\d+)\s*[,/-]\s*([A-Za-z\s]+)(?:[,/-]\s*([A-Za-z\s]+))?(?:[,/-]\s*(\d{6}))?/;
  const websiteRegex = /https?:\/\/(?:www\.)?[^\s/$.?#].[^\s]*/g;

  // const indianLanguages = [
  //   "eng", // English
  //   "hin", // Hindi
  //   "ben", // Bengali
  //   "tam", // Tamil
  //   "tel", // Telugu
  //   "guj", // Gujarati
  //   "kan", // Kannada
  //   "mal", // Malayalam
  //   "ori", // Odia
  //   "pan", // Punjabi
  //   "asm", // Assamese
  //   "mar", // Marathi
  //   "san", // Sanskrit
  // ];

  // Combine languages into a single string for Tesseract
  // const languages = indianLanguages.join("+");

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setDevices(videoDevices);
        console.log("devices enumer", devices);
        // if (videoDevices.length > 0) {
        //   setSelectedDeviceId(videoDevices[0].deviceId);
        // }
        if (videoDevices.length > 0) {
          // Select the rear camera by default
          const mainCamera = videoDevices.filter((device) =>
            device.label.toLowerCase().includes("back")
          );
          setSelectedDeviceId(
            mainCamera.length > 0
              ? mainCamera[mainCamera.length - 1].deviceId
              : videoDevices[0].deviceId
          );
        }
      } catch (error) {
        setCameraError("Failed to access camera devices.");
        console.error("Camera Devices Error:", error);
      }
    };

    fetchDevices();
  }, []);

  useEffect(() => {
    if (isCameraOpen) {
      openCamera();
    }

    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setIsCameraOpen(false);
        if (currentStream) {
          currentStream.getTracks().forEach((track) => track.stop());
        }
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
      if (currentStream) {
        currentStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [isCameraOpen]);

  const openCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: { ideal: "environment" },
          deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined,

          // facingMode : { exact : 'environment'},
          // width: { min: 1024, ideal: 1280, max: 1920 },
          // height: { min: 576, ideal: 720, max: 1080 },
          width: { ideal: 1920 },
          height: { ideal: 1080 },
        },
      });
      setCurrentStream(stream);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      setCameraError("Camera access is not supported in this browser.");
      console.error("Camera Error:", error);
    }
  };

  const capturePhoto = () => {
    setShowLoader(true);
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      if (video.videoWidth && video.videoHeight) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(async (blob) => {
          //   const imageUrl = URL.createObjectURL(blob);
          if (currentSide === "front") {
            await processImage(blob, "front");
          } else if (currentSide === "back") {
            await processImage(blob, "back");
          }
          // setIsCameraOpen(false);
          setShowLoader(false);
        }, "image/png");
      } else {
        console.error("Video resolution not available.");
        setShowLoader(false);
        // alert("Video resolution not available.");
      }
    }
  };

  // const processImage = async (blob, side) => {
  //   const img = new Image();
  //   img.src = URL.createObjectURL(blob);

  //   img.onload = () => {
  //     // Create a canvas to draw the image
  //     const canvas = document.createElement("canvas");
  //     const context = canvas.getContext("2d");

  //     // Set canvas dimensions to match the image
  //     canvas.width = img.width;
  //     canvas.height = img.height;

  //     // Draw the image on the canvas
  //     context.drawImage(img, 0, 0, img.width, img.height);

  //     // Calculate the height of each third
  //     const thirdHeight = img.height / 3;

  //     // Define the cropping area for the middle third, adjusted slightly down
  //     const cropY = thirdHeight + thirdHeight * 0.05; // Move down by 10% of a third
  //     const cropHeight = thirdHeight;

  //     // Create a new canvas for the cropped image
  //     const croppedCanvas = document.createElement("canvas");
  //     croppedCanvas.width = img.width;
  //     croppedCanvas.height = cropHeight + 0.05;
  //     const croppedContext = croppedCanvas.getContext("2d");

  //     // Draw the adjusted middle third onto the new canvas
  //     croppedContext.drawImage(
  //       canvas,
  //       0,
  //       cropY, // Source x, y
  //       img.width,
  //       cropHeight, // Source width, height
  //       0,
  //       0, // Destination x, y
  //       img.width,
  //       cropHeight // Destination width, height
  //     );

  //     // Convert the cropped canvas to a data URL or blob
  //     const croppedImage = croppedCanvas.toDataURL("image/png");

  //     // Set the cropped image to state based on side
  //     // if (side === 'front') {
  //     //   setImageFront(croppedImage);
  //     // } else if (side === 'back') {
  //     //   setImageBack(croppedImage);
  //     // }
  //     setCroppedImageSrc(croppedImage);

  //     // Show options to the user
  //     setShowOptions(true);

  //     // Clean up
  //     URL.revokeObjectURL(img.src);
  //   };
  // };

//   const processImage = async (blob, side) => {
//     const img = new Image();
//     img.src = URL.createObjectURL(blob);

//     img.onload = () => {
//         // Create a canvas to draw the image
//         const canvas = document.createElement("canvas");
//         const context = canvas.getContext("2d");

//         // Set canvas dimensions to match the image
//         canvas.width = img.width;
//         canvas.height = img.height;

//         // Draw the image on the canvas
//         context.drawImage(img, 0, 0, img.width, img.height);

//         // Determine the aspect ratio
//         const aspectRatio = img.width / img.height;

//         // Adjust the cropping based on aspect ratio
//         let cropX, cropY, cropWidth, cropHeight;

//         if (aspectRatio > 1.5) {
//             // Wide aspect ratio (likely laptop/PC)
//             cropHeight = img.height * 0.6; // Use 60% of the height
//             cropWidth = img.width * 0.8; // Use 80% of the width
//             cropX = (img.width - cropWidth) / 2; // Center the crop horizontally
//             cropY = (img.height + 0.05 - cropHeight) / 2; // Center the crop vertically
//         } else {
//             // Narrower aspect ratio (likely mobile)
//             cropX = 0; // Full width
//             cropY = img.height / 3 + img.height * 0.01; // Adjusted crop for mobile
//             cropWidth = img.width; // Full width
//             cropHeight = img.height / 3;
//         }

//         // Create a new canvas for the cropped image
//         const croppedCanvas = document.createElement("canvas");
//         croppedCanvas.width = cropWidth;
//         croppedCanvas.height = cropHeight;
//         const croppedContext = croppedCanvas.getContext("2d");

//         // Draw the adjusted central part onto the new canvas
//         croppedContext.drawImage(
//             canvas,
//             cropX,
//             cropY, // Source x, y
//             cropWidth,
//             cropHeight, // Source width, height
//             0,
//             0, // Destination x, y
//             cropWidth,
//             cropHeight // Destination width, height
//         );

//         // Convert the cropped canvas to a data URL or blob
//         const croppedImage = croppedCanvas.toDataURL("image/png");

//         // Set the cropped image to state based on side
//         setCroppedImageSrc(croppedImage);

//         // Show options to the user
//         setShowOptions(true);

//         // Clean up
//         URL.revokeObjectURL(img.src);
//     };
// };


const processImage = async (blob, side) => {
  const img = new Image();
  img.src = URL.createObjectURL(blob);

  img.onload = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = img.width;
    canvas.height = img.height;
    context.drawImage(img, 0, 0, img.width, img.height);

    const aspectRatio = img.width / img.height;
    let cropX, cropY, cropWidth, cropHeight;

    if (aspectRatio > 1.5) {
      cropHeight = img.height * 0.7;
      cropWidth = img.width * 0.8;
      cropX = (img.width - cropWidth) / 2;
      cropY = (img.height - cropHeight) / 2;
    } else {
      cropX = 0;
      cropY = img.height / 3;
      cropWidth = img.width;
      cropHeight = img.height / 2.6 ;
    }

    // Create display version (color)
    const displayCanvas = document.createElement("canvas");
    displayCanvas.width = cropWidth;
    displayCanvas.height = cropHeight;
    const displayContext = displayCanvas.getContext("2d");
    displayContext.drawImage(
      canvas,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight
    );

    // Create OCR version (grayscale with noise reduction)
    const ocrCanvas = document.createElement("canvas");
    ocrCanvas.width = cropWidth;
    ocrCanvas.height = cropHeight;
    const ocrContext = ocrCanvas.getContext("2d");
    ocrContext.drawImage(
      canvas,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight
    );

    // Convert to grayscale
    const imageData = ocrContext.getImageData(0, 0, cropWidth, cropHeight);
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
      data[i] = avg; // red
      data[i + 1] = avg; // green
      data[i + 2] = avg; // blue
    }
    ocrContext.putImageData(imageData, 0, 0);

    // Reduce noise (simple blur)
    ocrContext.filter = 'blur(1px)';
    ocrContext.drawImage(ocrCanvas, 0, 0);
    ocrContext.filter = 'none';

    const displayImage = displayCanvas.toDataURL("image/png");
    const ocrImage = ocrCanvas.toDataURL("image/png");

    setCroppedImageSrc(displayImage);
    setOcrImageSrc(ocrImage); // New state for OCR image
    setShowOptions(true);
    URL.revokeObjectURL(img.src);
  };
};

const handleTesseractOcr = async () => {
  setShowOptions(false);
  setIsLoading(true);
  setProgress(0);
  if (currentSide === "front") {
    setImageFront(croppedImageSrc);
  } else if (currentSide === "back") {
    setImageBack(croppedImageSrc);
  }
  const worker = await Tesseract.createWorker('eng', 1, {
    logger: (info) => {
      if (info.status === "recognizing text") {
        setProgress(Math.floor(info.progress * 100));
      }
    },
  });
  await worker
    .recognize(ocrImageSrc) // Use ocrImageSrc instead of croppedImageSrc
    .then(({ data }) => {
      extractText(data.text, currentSide, data.words);
      setIsLoading(false);
    })
    .catch((error) => {
      console.error("OCR Error:", error);
      setShowLoader(false);
      setIsLoading(false);
    });
};


  // const handleTesseractOcr = async () => {
  //   setShowOptions(false);
  //   setIsLoading(true);
  //   setProgress(0);
  //   if (currentSide === "front") {
  //     setImageFront(croppedImageSrc);
  //   } else if (currentSide === "back") {
  //     setImageBack(croppedImageSrc);
  //   }
  //   const worker = await Tesseract.createWorker('eng', 1, {
  //     logger: (info) => {
  //       if (info.status === "recognizing text") {
  //         setProgress(Math.floor(info.progress * 100)); // Update progress
  //       }
  //     },
      
  //   });
  //   await worker
  //     .recognize(croppedImageSrc)
  //     .then(({ data }) => {
  //       extractText(data.text, currentSide, data.words);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("OCR Error:", error);
  //       setShowLoader(false);
  //       setIsLoading(false);
  //     });
  // };

  const extractText = (text, side, extractedWords) => {
    try {
      console.log("text in processing mode", text);
      // alert(text);

      const nameMatch = text.match(nameRegex);
      const emailMatch = text.match(emailRegex);
      const phoneMatch = text.match(phoneRegex);
      const websiteMatch = text.match(websiteRegex);
      const addressMatches = [
        text.match(addressRegex1),
        text.match(addressRegex2),
        text.match(addressRegex3),
        text.match(addressRegex4),
      ].filter((match) => match);
      const extractedAddress =
        addressMatches.length > 0
          ? addressMatches[0]?.slice(1)?.filter(Boolean)?.join(", ")
          : "";

      console.log("phone ", phoneMatch);

      const data = {
        name: nameMatch ? nameMatch[0] : "",
        email: emailMatch ? emailMatch[0] : "",
        phone: phoneMatch ? phoneMatch[0] : "",
        alternatePhone: phoneMatch ? (phoneMatch[1] ? phoneMatch[1] : "") : "",
        address: extractedAddress,
        website: websiteMatch ? websiteMatch[0] : "",
      };

      if (side === "front") {
        setExtractedDataFront({
          name: data.name,
          email: data.email,
          phone: data.phone,
          address: data.address,
          website: data.website,
        });
      } else if (side === "back") {
        setExtractedDataBack(data);
      }

      const combinedData = {
        ...extractedDataFront,
        ...data,
      };

      if (side === "front") {
        setFormData(combinedData);
      }

      // const wordsArray = [...Object.values(data), ...text.split(/\s+/)];
      // let wordsArray = [...Object.values(data), ...text.split(/\s+/)];
      // wordsArray = wordsArray
      //   .map((word) => word.replace(/[^\w\s@.]/gi, "").trim()) // Remove unwanted special characters but keep @ and .
      //   .filter(Boolean);
      // setWords((prevWords) => [...new Set([...prevWords, ...wordsArray])]);

      // let wordsArray = [...Object.values(data), ...text.split(/\s+/)];
      // wordsArray = wordsArray.filter(Boolean);
      // setWords((prevWords) => {
      //   const newWords = [...new Set([...prevWords, ...wordsArray])];
      //   console.log("Updated Words Array:", newWords);
      //   alert(newWords);
      //   return newWords;
      // });

      // let wordsArray = [...Object.values(data), ...text.split(/\s+/)];

      const extractedTextArray = extractedWords
        ?.map((wordObj) => {
          if (wordObj.confidence > 70) {
            return wordObj.text;
          }
        })
        .filter(Boolean);
      let wordsArray = [...Object.values(data), ...extractedTextArray];

      // wordsArray = wordsArray
      //   .map((word) => word.replace(/[^a-zA-Z0-9@./]+/g, "").trim()) // Keep letters, numbers, @, ., and /
      //   .filter(Boolean);
      console.log("words array", wordsArray, extractedTextArray);

      setWords((prevWords) => {
        // const newWords = [...new Set([...prevWords, ...wordsArray])];
        const newWords = [...new Set([...wordsArray])];
        console.log("Updated Words Array:", newWords);
        // alert(newWords);
        return newWords;
      });
      setShowLoader(false);
      // alert(words)
    } catch (error) {
      setShowLoader(false);
      // alert(error);
    }

    // alert(words)
  };

  const clearImageAndData = () => {
    setImageFront("");
    setImageBack("");
    setExtractedDataFront({});
    setExtractedDataBack({});
    // setFormData({});
    setWords([]);
    setCurrentStream(null);
    setIsLoading(false);
    successToaster("Suggestions cleared successfully");
  };

  const handleRetake = () => {
    setShowOptions(false);
    setIsCameraOpen(true);
  };

  const openCamForFront = () => {
    setCurrentSide("front");
    setIsCameraOpen(true);
  }

  const closeCamForFront = () => {
    setCurrentSide("");
    setIsCameraOpen(false);
  }

  const handleCamForFront = () => {
    if(currentSide === 'front'){
      closeCamForFront()
    } else {
      openCamForFront()
    }
  }

  const openCamForBack = () => {
    setCurrentSide("back");
    setIsCameraOpen(true);
  }

  const closeCamForBack = () => {
    setCurrentSide("");
    setIsCameraOpen(false);
  }

  const handleCamForBack = () => {
    if(currentSide === 'back'){
      closeCamForBack()
    } else {
      openCamForBack()
    }
  }

  return (
    <div className="flex flex-col gap-4 p-4">
      {/* <div className="flex flex-col md:flex-row items-stretch justify-start gap-4">
        <div className="flex flex-col md:flex-row justify-center items-stretch md:items-center md:justify-start md:gap-4 md:flex-1">
          <label htmlFor="frontSide">Front Side : </label>
          <input
            type="file"
            name="frontSide"
            accept="image/*"
            onChange={(e) => handleImageUpload(e, "front")}
            className="p-2 border rounded"
            // placeholder="Front Side"
          />
        </div>
        <div className="flex flex-col md:flex-row justify-center items-stretch  md:items-center md:justify-start md:gap-4 md:flex-1">
          <label htmlFor="backSide"> Backside : </label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUpload(e, "back")}
            className="p-2 border rounded"
          />
        </div>
      </div> */}
      <div className="flex justify-between md:justify-start text-center items-center gap-2 w-full">
        <button
          onClick={() => {
            handleCamForFront()
          }}
          style={{
            border: currentSide === "front" && " 2px solid rgba(0,0,0, 0.4)",
          }}
          className="py-2 px-2 text-center w-5/12 md:w-auto md:flex-1 bg-blue-500 text-white rounded"
        >
          {/* Scan Front Side */}
          {
            currentSide === 'front' ? 'Close Front Side' : 'Scan Front Side'
          }
        </button>
        <button
          onClick={() => {
            handleCamForBack()
          }}
          style={{
            border: currentSide === "back" && " 2px solid rgba(0,0,0, 0.4)",
          }}
          className="py-2 px-2 text-center w-5/12 md:w-auto md:flex-1 bg-blue-500 text-white rounded"
        >
          {/* Scan Back Side */}
          {
            currentSide === 'back' ? 'Close Back Side' : 'Scan Back Side'
          }
        </button>
        {/* <button
        onClick={switchCamera}
        className="py-2 px-4 hidden md:block md:flex-1 text-center bg-green-500 text-white rounded"
      >
        Switch Camera
      </button> */}
      </div>

      {/* <button
        onClick={switchCamera}
        className="p-2 md:hidden bg-green-500 text-white rounded"
      >
        Switch Camera
      </button> */}

      {/* {
        devices.map(device => (
          <p>{device.label}</p>
        ))
      } */}

{isCameraOpen && (
  <div className="relative w-full max-w-3xl md:max-w-2xl mx-auto aspect-[5/5] md:aspect-[11/9] md:h-[35rem] overflow-hidden">
    <video
      ref={videoRef}
      playsInline
      className="absolute inset-0 w-full h-full object-cover"
      muted
    ></video>
    <div className="absolute inset-0 flex flex-col items-center justify-center pt-2">
      <span className="text-white font-bold text-center px-4 mb-2 bg-black bg-opacity-50 rounded">
        Make sure the card fits within the frame below
      </span>
      <div className="relative w-full max-w-sm md:max-w-full aspect-[3/2] border-2 border-dashed border-yellow-500 mt-2"></div>
      <button
      onClick={() => capturePhoto()}
      className="mx-auto mt-2 py-2 px-4 bg-yellow-500 text-white rounded"
    >
      Capture Photo
    </button>
    </div>
    
  </div>
)}

      {/* {isCameraOpen && (
        <div className="relative flex md:h-auto items-center justify-center">
          <video
            ref={videoRef}
            playsInline
            // style={{ width: "100%" }}
            className="border rounded w-full object-cover min-h-[27rem] overflow-hidden md:w-1/2"
            muted
          ></video>
          <div className="absolute inset-0 flex flex-col items-center justify-center pointer-events-none">
            <span className="inset-0 flex items-center justify-center text-white font-bold">
              Make sure the card fits within the frame below
            </span>
            <div className="relative w-full md:w-5/12 h-60 aspect-h-9 bg-opacity-30 border-2 border-dashed border-yellow-500"></div>
          </div>
          <button
            onClick={() => capturePhoto()}
            className="absolute bottom-3 left-1/2 transform -translate-x-1/2 p-2 bg-yellow-500 text-white rounded"
          >
            Capture Photo
          </button>
        </div>
      )} */}

      {showOptions && (
        <div className="fixed inset-0 flex flex-col gap-4 justify-center items-center w-full bg-black/30 backdrop-blur-sm ">
          
          {croppedImageSrc && (
            <img
              src={croppedImageSrc}
              alt="cropped image"
              className=" rounded object-cover w-11/12 lg:w-6/12 mx-auto md:mt-12 "
            />
          )}
         
          <div className="flex items-center justify-center gap-4 bg-opacity-75 p-4">
            <button
              onClick={() => handleTesseractOcr()}
              className="py-2 px-4 bg-blue-500 text-white rounded"
            >
              Process {currentSide} Image
            </button>
            <button
              onClick={() => handleRetake()}
              className="py-2 px-4 bg-red-500 text-white rounded"
            >
              Retake Image
            </button>
          </div>
        </div>
      )}
      {/* {showOptions && (
        <div className="flex items-center justify-center gap-4 bg-opacity-75 p-4">
          <button
            onClick={() => handleTesseractOcr()}
            className="py-2 px-4 bg-blue-500 text-white rounded"
          >
            Process {currentSide} Image
          </button>
          <button
            onClick={() => handleRetake()}
            className="py-2 px-4 bg-red-500 text-white rounded"
          >
            Retake Image
          </button>
        </div>
      )} */}

      {/* {isCropping && (
        <div className="relative w-full h-[30rem]">
          <Cropper
            image={currentSide === "front" ? imageFront : imageBack}
            crop={crop}
            zoom={zoom}
            aspect={16 / 9}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            // classes='w-full h-screen'
            onCropChange={setCrop}
            onCropComplete={handleCropComplete}
            onZoomChange={setZoom}
          />
          <button
            onClick={handleCrop}
            className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-blue-500 text-white px-4 py-2"
          >
            Crop & Process
          </button>
          <button
            onClick={handleRetake}
            className="absolute bottom-4 right-4 bg-red-500 text-white px-4 py-2"
          >
            Retake
          </button>
        </div>
      )} */}

      <div className="grid grid-cols-1 md:grid-cols-2">
        {imageFront && (
          <div className="hidden lg:block">
            <div>Image Front :</div>
            <img
              src={imageFront}
              alt="Uploaded"
              className="hidden lg:flex mt-4 w-2/3 rounded object-cover"
            />
          </div>
        )}
        {imageBack && (
          <div className="hidden lg:block">
            <div>Image Back :</div>
            <img
              src={imageBack}
              alt="Uploaded"
              className="hidden lg:flex mt-4 w-2/3 rounded object-cover"
            />
          </div>
        )}
        {imageFront && (
          <div className="lg:hidden">
            <div>Image Front :</div>
            <img
              src={imageFront}
              alt="Uploaded"
              className="lg:hidden mt-4 rounded object-contain"
            />
          </div>
        )}
        {imageBack && (
          <div className="lg:hidden">
            <div>Image Back : </div>
            <img
              src={imageBack}
              alt="Uploaded"
              className="lg:hidden mt-4 rounded object-contain"
            />
          </div>
        )}
      </div>
      {isLoading && (
        <div className="text-center">
          <p>Extracting Information...</p>
        </div>
      )}

      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-10 bg-gray-800 bg-opacity-75">
          <p className="text-2xl text-white">Processing... {progress}%</p>
        </div>
      )}

      <canvas
        ref={canvasRef}
        style={{ display: "none" }}
        width="640"
        height="480"
      ></canvas>
      {cameraError && <p className="text-red-500">{cameraError}</p>}
      {/* {ocrError && <p className="text-red-500">{ocrError}</p>} */}
      <button
        type="button"
        onClick={() => clearImageAndData()}
        className="py-2 px-4 text-center bg-red-500 text-white rounded"
      >
        Clear Suggesstions
      </button>
    </div>
  );
};

export default OCR2;
