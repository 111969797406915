import React, { useState } from "react";
import WordModal from "./modals/WordModal";

import * as Yup from "yup";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { errorToaster, successToaster } from "../../ReactToastify";
import Loader from "../../Loader";

import { instance } from "../../../utils/axios";
import TextInput from "../../common/TextInput";
import { SendWhatsappModal } from "./modals/SendWhatsappModal";
import { SaveContactModal } from "./modals/SaveContactModal";

const FillForm = ({
  words,
  formData,
  setFormData,
  setWords,
  setImageBack,
  setImageFront,
}) => {

  const token = useSelector((state) => state.auth.token);
  const userId = useSelector((state) => state.auth.userId);
  const [modalData, setModalData] = useState({
    isOpen: false,
    field: "",
  });
  const [showWhatsapp, setShowWhatsapp] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [dataForWhatsapp, setDataForWhatsapp] = useState(null)
  const [showContactModal, setShowContactModal] = useState(false)

  const openModal = (field) => {
    setModalData({
      isOpen: true,
      field,
    });
  };

  const closeModal = () => {
    setModalData({
      ...modalData,
      isOpen: false,
    });
  };

  const handleWordPaste = (selectedWords) => {
    setFormData({
      ...formData,
      [modalData.field]: selectedWords.join(" "),
    });
  };

  const handleFieldChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handleResetForm = () => {
    setWords([]);
    setFormData({
      name: "",
      email: "",
      phone: "",
      address: "",
      website: "",
      businessName: "",
      alternatePhone: "",
      city: "",
      state: "",
      pincode: "",
      status: "",
      importance: "",
      source: "",
      archive: false,
    });
  };

  

  const normalizePhoneNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.replace(/\s+/g, ""); // Remove all spaces

    if (/[a-zA-Z]/.test(phoneNumber)) {
      return errorToaster("Phone number cannot contain letters!"); // Return as is if it contains letters
    }

    // If the number starts with +91 or 91 and has more than 10 characters
    if (phoneNumber.length > 10) {
      if (phoneNumber.startsWith("+91")) {
        phoneNumber = phoneNumber.substring(3); // Remove +91
      } else if (phoneNumber.startsWith("91")) {
        phoneNumber = phoneNumber.substring(2); // Remove 91
      }
    }

    // After removing country codes, ensure the length is 10
    if (phoneNumber.length > 10) {
      phoneNumber = phoneNumber.substring(phoneNumber.length - 10); // Keep only the last 10 digits
    }

    // If the resulting length is exactly 10, add the country code
    if (phoneNumber.length === 10) {
      phoneNumber = "91" + phoneNumber; // Add 91 country code
    }

    return phoneNumber;
  };

  const handleSaveDetails = async (values, resetForm) => {
    setIsLoading(true);
    if (!userId) {
      return errorToaster("Unable to fetch userID");
    }
    const { name, phone, alternatePhone, ...data } = values;

    if(!phone){
      setIsLoading(false)
      return errorToaster("Phone number is required")
    }
    if(!data.email){
      setIsLoading(false)
      return errorToaster("Email is required")
    }

    if (/[a-zA-Z]/.test(phone)) {
      setIsLoading(false);
      return errorToaster("Phone number cannot contain letters!", 1000); // Return as is if it contains letters
    }

    if (/[a-zA-Z]/.test(alternatePhone)) {
      setIsLoading(false);
      return errorToaster("Phone number cannot contain letters!", 1000); // Return as is if it contains letters
    }

    if (phone !== "" && phone.length < 10) {
      setIsLoading(false);
      return errorToaster("Phone number must be atleast 10 digit!", 1000);
    }

    if (alternatePhone !== "" && alternatePhone.length < 10) {
      setIsLoading(false);
      return errorToaster(
        "Alternate Phone number must be atleast 10 digit!",
        1000
      );
    }

    const payload = {
      ...data,
      phone: normalizePhoneNumber(phone),
      alternatePhone: normalizePhoneNumber(alternatePhone),
      viewerName: name,
      exprienceName: "OCR",
      type: "global",
      userId: userId,
      // isArchieved : false,
      generatedFrom: "ocr",
      status: "",
      source: "",
      importance: "",
      archive: false,
    };

    try {
      const response = await instance.post(`/inquiries/add_inquiry`, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          ocr: true,
        },
      });
      console.log(response);
      setDataForWhatsapp(payload)
      resetForm();
      handleResetForm();
      setImageBack(null);
      setImageFront(null);
      setIsLoading(false);
      
      successToaster("Form submitted successfully!");
      setShowWhatsapp(true)
      return true;
    } catch (error) {
      console.log(error.response?.data);
      setIsLoading(false);
      // alert(error);
      errorToaster(error.response?.data?.message);
      return false;
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    businessName: Yup.string(),
    website: Yup.string(),
    email: Yup.string().email("Invalid email"),
    phone: Yup.string(),
    alternatePhone: Yup.string(),
    address: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    pincode: Yup.string(),
  });

  const SampleForm = ({ formData, openModal }) => {
    return (
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          await handleSaveDetails(values, resetForm);
          
        }}
      >
        {({ resetForm }) => (
          <Form>
            {/* <div className="grid grid-cols-1 md:grid-cols-2">
            {[
              'name',
              'businessName',
              'website',
              'email',
              'phone',
              'alternatePhone',
              'address',
              'city',
              'state',
              'pincode',
            ].map((field) => (
              <div key={field} className="flex flex-col items-start px-4 py-2 justify-center w-full">
                <label htmlFor={field} className="my-2">
                  {capitalizeFirstLetter(field)}
                  {
                    field === 'name' || field === 'email' || field === 'phone'
                  }
                  {/* {(field === 'phone' || field === 'alternatePhone') && (
                  <span className="text-gray-500 text-sm ml-2">
                    (Add +91 prefix)
                  </span>
                )} */}
            {/* </label>
                <div className="flex items-center justify-between p-3 border-2 border-gray-200 bg-gray-100 rounded-lg w-full">
                  <Field
                    type="text"
                    id={field}
                    name={field}
                    placeholder={field}
                    className="bg-gray-100 outline-none w-full"
                  />
                  <FontAwesomeIcon
                    icon={faLightbulb}
                    onClick={() => openModal(field)}
                    className="cursor-pointer"
                  />
                </div>
                <ErrorMessage name={field} component="div" className="text-red-500 text-sm mt-1" />
              </div>
            ))}
            </div> */}

            <div className="grid grid-cols-1 md:grid-cols-2">
              <TextInput
                field="name"
                label="Name"
                type="text"
                placeholder="John Doe"
                isRequired={true}
                openModal={openModal}
              />
              <TextInput
                field="businessName"
                label="Business Name"
                type="text"
                placeholder="IT Futurz"
                isRequired={false}
                openModal={openModal}
              />
              <TextInput
                field="website"
                label="Website"
                type="text"
                placeholder="www.example.com"
                isRequired={false}
                openModal={openModal}
              />
              <TextInput
                field="email"
                label="Email"
                type="email"
                placeholder="johndoe@example.com"
                isRequired={true}
                openModal={openModal}
              />
              <TextInput
                field="phone"
                label="Phone"
                type="text"
                placeholder="919876543210"
                isRequired={true}
                openModal={openModal}
              />
              <TextInput
                field="alternatePhone"
                label="Alternate Phone"
                type="text"
                placeholder="919876543210"
                isRequired={false}
                openModal={openModal}
              />
              <TextInput
                field="address"
                label="Address"
                type="text"
                placeholder="Enter your address here..."
                isRequired={false}
                openModal={openModal}
              />
              <TextInput
                field="city"
                label="City"
                type="text"
                placeholder="Eg : Mumbai"
                isRequired={false}
                openModal={openModal}
              />
              <TextInput
                field="state"
                label="State"
                type="text"
                placeholder="Eg : Maharashtra"
                isRequired={false}
                openModal={openModal}
              />
              <TextInput
                field="pincode"
                label="Pincode"
                type="text"
                placeholder="Eg : 400054"
                isRequired={false}
                openModal={openModal}
              />
            </div>

            {/* <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Submit
            </button> */}
            <div className="flex items-center justify-end gap-4 p-4">
              <button
                type="reset"
                onClick={() => {
                  resetForm();
                  handleResetForm();
                }}
                className=" inline-flex items-center justify-center rounded-lg bg-red-600 py-2 px-6 font-dm text-base font-medium text-white transition-transform duration-200 ease-in-out hover:scale-[1.02]"
              >
                Clear
              </button>
              <button
                type="submit"
                // onClick={handleSaveDetails}
                className=" inline-flex items-center justify-center rounded-lg bg-blue-800 py-2 px-6 font-dm text-base font-medium text-white transition-transform duration-200 ease-in-out hover:scale-[1.02]"
              >
                Save Form
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const handleCloseWhatsappModal = () => {
    setShowWhatsapp(false)
    // setDataForWhatsapp(null)
    setShowContactModal(true)
  }

  const handleCloseSaveContactModal = () => {
    setShowContactModal(false)
    setDataForWhatsapp(null)
  }

  return (
    <div className="pb-12">
      {isLoading && <Loader isLoading={isLoading} />}

      {/* <h2 className=" text-3xl mx-4">Fill Form</h2> */}
      <div className="  w-full">
        {/* {[
          "name",
          "businessName",
          "website",
          "email",
          "phone",
          'alternatePhone',
          "address",
          "city",
          "state",
          "pincode",
        ].map((field) => (
            <div className="flex flex-col items-start px-4 py-2 justify-center w-full">
              <label htmlFor={field} className="my-2">{capitalizeFirstLetter(field)}</label>
              <div className="flex items-center justify-between p-3 border-2 border-gray-300 bg-gray-200 rounded-lg w-full">
                <input
                  type="text"
                  id={field}
                  placeholder={field}
                  value={formData[field]}
                  className=" bg-gray-200 outline-none w-full"
                  onChange={handleFieldChange(field)}
                />
                <FontAwesomeIcon
                  icon={faLightbulb}
                  onClick={() => openModal(field)}
                  className=" cursor-pointer"
                />
              </div>
            </div>
          ))} */}
        <SampleForm
          formData={formData}
          handleFieldChange={handleFieldChange}
          openModal={openModal}
        />
        {/* <div className="flex items-center justify-end">
            <button type="button"
              onClick={handleSaveDetails}
              className="mx-8 mt-8 inline-flex items-center justify-center rounded-xl bg-green-600 py-3 px-6 font-dm text-base font-medium text-white shadow-xl shadow-green-400/75 transition-transform duration-200 ease-in-out hover:scale-[1.02]"

            >
              Save Form
            </button>
          </div> */}
      </div>
      <WordModal
        isOpen={modalData.isOpen}
        onRequestClose={closeModal}
        words={words}
        onPaste={handleWordPaste}
      />
      <SendWhatsappModal
        showWhatsapp={showWhatsapp}
        handleCloseWhatsappModal={handleCloseWhatsappModal}
        dataForWhatsapp={dataForWhatsapp}
      />
      <SaveContactModal
        dataForSaveContact={dataForWhatsapp}
        handleCloseSaveContactModal={handleCloseSaveContactModal}
        showContactModal={showContactModal}
      />
    </div>
  );
};

export default FillForm;
