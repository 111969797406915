import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field } from "formik";
import React from "react";

const TextInput = ({
    field,
    label,
    type,
    placeholder,
    extraLabel,
    isRequired,
    openModal,
}) => {
    const capitalizeFirstLetter = (string) =>
        string.charAt(0).toUpperCase() + string.slice(1);

    return (
        <div
            key={field}
            className="flex flex-col items-start px-4 py-2 justify-center w-full"
        >
            <label htmlFor={field} className="my-2">
                {
                    capitalizeFirstLetter(label)
                }
                {
                    extraLabel && extraLabel
                }
                {
                    isRequired && <span className="text-red-500 font-semibold ">*</span>
                }
            </label>
            <div className="flex items-center justify-between p-3 border-2 border-gray-200 bg-gray-100 rounded-lg w-full">
                <Field
                    type={type}
                    id={field}
                    name={field}
                    placeholder={placeholder}
                    className="bg-gray-100 outline-none w-full"
                />
                <FontAwesomeIcon
                    icon={faLightbulb}
                    onClick={() => openModal(field)}
                    className="cursor-pointer"
                />
            </div>
            <ErrorMessage
                name={field}
                component="div"
                className="text-red-500 text-sm mt-1"
            />
        </div>
    );
};

export default TextInput;
