import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faShareAlt, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { instance } from "../../utils/axios";

const EnquiryDetails = () => {
  const { id } = useParams();
  const [enquiry, setEnquiry] = useState({
    "_id": "66bb2dde1780a67040ca42f7",
    "email": "kinesh77@gmail.com",
    "phone": "+919867685121",
    "address": "22195\nHarsh Bhaga, t,  ",
    "alternatePhone": "",
    "viewerName": "Harsh Bhagat",
    "exprienceName": "OCR",
    "type": "global",
    "userId": "65ccbb7e443b8ebcbb260e85",
    "createdAt": "2024-08-13T09:56:46.534Z"
});
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchEnquiryDetail = async () => {
      try {
        const response = await instance.get(`/inquiries/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setEnquiry(response.data?.data);
      } catch (error) {
        console.error("Failed to fetch enquiry details", error);
      }
    };

    fetchEnquiryDetail();
  }, [id, token]);

  if (!enquiry) return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      <p className=" font-medium mt-4">No Data Found!</p>
    </div>
  );

  const handlePhone = () => {
    window.location.href = `tel:${enquiry.phone}`;
  };

  const handleEmail = () => {
    window.location.href = `mailto:${enquiry.email}`;
  };

  const handleWhatsApp = () => {
    const message = encodeURIComponent("Hello, I'm contacting you regarding your enquiry.");
    window.open(`https://wa.me/${enquiry.phone}?text=${message}`, '_blank');
  };

  const handleShare = () => {
    // Placeholder for share functionality
    console.log("Share functionality to be implemented");
  };

  const handleSaveContact = () => {
    const vCard = `BEGIN:VCARD
VERSION:3.0
FN:${enquiry.viewerName}
TEL:${enquiry.phone}
EMAIL:${enquiry.email}
END:VCARD`;

    const blob = new Blob([vCard], { type: "text/vcard;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${enquiry.viewerName}.vcf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="container mx-auto p-4 pt-24 max-w-3xl">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Enquiry Details</h1>
      
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="p-4 bg-gray-100 flex justify-between items-center flex-wrap">
          <ActionButton icon={faPhone} onClick={handlePhone} label="Call" />
          <ActionButton icon={faEnvelope} onClick={handleEmail} label="Email" />
          <ActionButton icon={faWhatsapp} onClick={handleWhatsApp} label="WhatsApp" />
          <ActionButton icon={faShareAlt} onClick={handleShare} label="Share" />
          <ActionButton icon={faAddressBook} onClick={handleSaveContact} label="Save Contact" />
        </div>

        <div className="p-6 space-y-4">
          <DetailItem label="Viewer Name" value={enquiry.viewerName} />
          {/* <DetailItem label="Experience Name" value={enquiry.exprienceName} /> */}
          {/* <DetailItem label="Type" value={enquiry.type} /> */}
          <DetailItem label="Email" value={enquiry.email} />
          <DetailItem label="Phone" value={enquiry.phone} />
          <DetailItem label="Alternate Phone" value={enquiry.alternatePhone} />
          <DetailItem label="Business Name" value={enquiry.businessName} />
          <DetailItem label="Website" value={enquiry.wesbite} />
          <DetailItem label="Address" value={enquiry.address} />
          <DetailItem label="City" value={enquiry.city} />
          <DetailItem label="State" value={enquiry.state} />
          <DetailItem label="Pincode" value={enquiry.pincode} />
          <DetailItem label="Created At" value={new Date(enquiry.createdAt).toLocaleString()} />
        </div>
      </div>
    </div>
  );
};

const DetailItem = ({ label, value }) => (
  <div className="flex flex-col sm:flex-row sm:justify-between border-b border-gray-200 py-3 last:border-b-0">
    <span className="font-medium text-gray-600">{label}:</span>
    <span className="text-gray-800 mt-1 sm:mt-0">{value || "N/A"}</span>
  </div>
);

const ActionButton = ({ icon, onClick, label }) => (
  <button 
    onClick={onClick}
    className="flex flex-col items-center p-2 text-blue-600 hover:text-blue-800 transition-colors duration-200"
  >
    <FontAwesomeIcon icon={icon} size="lg" />
    <span className="text-xs mt-1">{label}</span>
  </button>
);

export default EnquiryDetails;