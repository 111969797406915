import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Input, Radio, RadioGroup, Typography } from "@mui/material";
import React, { useState } from "react";
import { instance } from "../../../utils/axios";
import { errorToaster } from "../../ReactToastify";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { CSVDownload, CSVLink } from "react-csv";

const ExportToCSVModal = ({ showExportCsvModal, setShowExportCsvModal }) => {

    const [radioValue, setRadioValue] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const token = useSelector((state) => state.auth.token);
    const [data, setData] = useState([])

    const handleCloseExportToCsvModal = () => {
        setShowExportCsvModal(false);
        setStartDate("");
        setEndDate("");
        setRadioValue("");
        setData([])
    };

    const handleReport = async () => {
        if (!startDate && !endDate) {
            errorToaster("Please Provide Valid Date");

            return;
        }
        try {
            const res = await instance.get(
                "/inquiries/get_exp_inquiry_csv/OCR",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        startDate,
                        endDate
                    }
                }
            );
            console.log(res, "response");

            if (res?.data?.data?.data?.length === 0) {
                errorToaster("No Record Found On This Date");
            } else {
                setData(res.data.data.data);
                // Automatically trigger download
                setTimeout(() => {
                    handleCloseExportToCsvModal(); // Close modal after setting data
                }, 0); // Ensures the state has updated before closing
            }
        } catch (error) {
            console.log(error);
            errorToaster(error?.response?.data?.message)
        }
    };

    const handleDate = (value) => {
        if (value === "Today") {
            const today = moment()
            setStartDate(today.startOf("day").format("YYYY-MM-DD"));
            setEndDate(today.endOf("day").format("YYYY-MM-DD"));
        } else if (value === "Last Week") {
            const startOfWeek = moment().startOf("week");
            setStartDate(startOfWeek.subtract(1, "week").startOf("day").format("YYYY-MM-DD"));
            setEndDate(startOfWeek.endOf("week").format("YYYY-MM-DD"));
        } else if (value === "Last Month") {
            const startOfMonth = moment().startOf("month");
            setStartDate(startOfMonth.subtract(1, "month").startOf("month").format("YYYY-MM-DD"));
            setEndDate(startOfMonth.endOf("month").format("YYYY-MM-DD"));
        } else if (value === "Custom") {
            setStartDate("");
            setEndDate("");
        }
    };


    return (
        <Dialog
            open={showExportCsvModal}
            onClose={handleCloseExportToCsvModal}
            minWidth="xl"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                padding: '1rem'
            }}
        >
            <DialogTitle id="alert-dialog-title" textAlign={"center"}>
                Download Enquiries Data
            </DialogTitle>
            <DialogContent>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    padding={"0 30px 20px 30px"}
                >
                    <Box mb={2}>
                        <FormControl>
                            <Typography variant="body" marginBottom={0.5}>
                                Select Date
                            </Typography>
                            <RadioGroup
                                sx={{ marginLeft: "10px" }}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={radioValue}
                                onChange={(e) => {
                                    setRadioValue(e.target.value);
                                    handleDate(e.target.value);
                                }}
                            >
                                <FormControlLabel value="Today" control={<Radio />} label="Today" />
                                <FormControlLabel value="Last Week" control={<Radio />} label="Last Week" />
                                <FormControlLabel value="Last Month" control={<Radio />} label="Last Month" />
                                <FormControlLabel value="Custom" control={<Radio />} label="Custom" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    {radioValue === "Custom" && (
                        <Box display={"flex"} gap={2}>
                            <Box>
                                <Typography variant="body">From : </Typography>
                                <Input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => {
                                        setStartDate(e.target.value);
                                    }}
                                />
                            </Box>
                            <Box>
                                <Typography variant="body">To : </Typography>
                                <Input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => {
                                        setEndDate(e.target.value);
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
                {
                    data.length > 0 && <CSVDownload data={data} target="_blank" />
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseExportToCsvModal}>Cancel</Button>
                <Button onClick={handleReport} variant="contained" color="success">
                    Downlaod
                    {/* <CSVLink
                    data={data}
                    asyncOnClick={true}
                    onClick={(event, done) => handleReport(done)}
                >
                    Download me
                </CSVLink> */}
                </Button>

            </DialogActions>
        </Dialog>
    )
}

export default ExportToCSVModal