import React, { useEffect, useState } from 'react';
// import OCRComponent from './OCRComponent'
import FillForm from './FillForm';
import Loader from '../../Loader';
import OCR2 from './OCR2';
import { useSelector } from 'react-redux';

const Home = () => {
    const [words, setWords] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const token = useSelector(state => state.auth.token)
    const [imageFront, setImageFront] = useState(null);
  const [imageBack, setImageBack] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    website : '',
    businessName : '',
    alternatePhone : '',
    city : '',
    state : '',
    pincode : '',
    source : '',
    importance : '',
    status : '',
    archive : false
  });

  console.log("token ,", token);
  

  useEffect(() => {
    if (showLoader) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [showLoader]);

    return (
        <div className=' relative flex flex-col pt-24'>
            {/* <OCRComponent setWords={setWords} words={words} setShowLoader={setShowLoader} setFormData={setFormData} formData={formData} /> */}
            <OCR2 setWords={setWords} words={words} setShowLoader={setShowLoader} setFormData={setFormData} formData={formData} 
              imageBack={imageBack}
              imageFront={imageFront}
              setImageBack={setImageBack}
              setImageFront={setImageFront}
            />
 
            <FillForm words={words} setWords={setWords} setFormData={setFormData} formData={formData}
              setImageBack={setImageBack}
              setImageFront={setImageFront}
            />
            {
              showLoader && <Loader isLoading={showLoader} />
            }
        </div>
    );
}

export default Home;