import { toast } from "react-toastify";

export const successToaster = (message, duration) => {
  return toast.success(message, {
    position: 'top-right',
    autoClose : duration ? duration : 501
  });
};

export const errorToaster = (message, duration) => {
  return toast.error(message, {
    position: 'top-right',
    autoClose : duration ? duration : 501
  });
};
