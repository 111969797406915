import React, { useState } from 'react';
import Modal from 'react-modal';

// Ensure to call Modal.setAppElement('#root') before rendering your app

Modal.setAppElement('#root');

const WordModal = ({ isOpen, onRequestClose, words, onPaste }) => {
  const [selectedWords, setSelectedWords] = useState([]);

  const handleWordToggle = (word) => {
    setSelectedWords((prevSelected) => {
      if (prevSelected.includes(word)) {
        return prevSelected.filter((w) => w !== word);
      } else {
        return [...prevSelected, word];
      }
    });
  };

  const handlePaste = () => {
    onPaste(selectedWords);
    setSelectedWords([]);
    onRequestClose();
  };

  const handleRequestClose = () => {
    setSelectedWords([]);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Select Words"
      className="relative bg-white max-h-[80vh] md:max-h-[60vh] m-8 items-center justify-center rounded-lg shadow-lg flex flex-col"
      overlayClassName="overlay fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
    >
      <div className="p-6 overflow-y-auto flex-grow">
        <h2 className="text-2xl font-semibold mb-4">Select Words</h2>
        <div className="flex flex-wrap gap-4 mb-6 overflow-y-scroll">
          {words.map((word, index) => (
            <div key={index} className="flex items-center space-x-1">
              <input
                type="checkbox"
                id={'word' + index}
                checked={selectedWords.includes(word)}
                onChange={() => handleWordToggle(word)}
                className="h-5 w-5 hidden peer"
              />
              <label htmlFor={'word' + index} className="text-base md:text-lg border-2 bg-gray-300 px-2 py-1 rounded-xl peer-checked:bg-green-500">{word}</label>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-white p-4 border-t border-gray-200 flex justify-center space-x-4 ">
        <button
          onClick={handlePaste}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Paste
        </button>
        <button
          onClick={handleRequestClose}
          className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg shadow hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
        >
          Close
        </button>
      </div>
    </Modal>

    // <>
    
    //   {isOpen ? (
    //     <>
    //       <div
    //         className="justify-center items-center flex overflow-x-hidden overflow-y-auto m-8 fixed inset-0 z-50 outline-none focus:outline-none"
    //       >
    //         <div className="relative w-auto my-6 mx-auto max-w-3xl">
              
    //           <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                
    //             <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
    //               <h3 className="text-3xl font-semibold">
    //                 Select Words 
    //               </h3>
    //               <button
    //                 className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
    //                 onClick={() => handleRequestClose()}
    //               >
    //                 <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
    //                   ×
    //                 </span>
    //               </button>
    //             </div>
                
    //             <div className="flex flex-wrap gap-4 mb-6 overflow-y-scroll p-4">
    //               {words.map((word, index) => (
    //                 <div key={index} className="flex items-center space-x-2">
    //                   <input
    //                     type="checkbox"
    //                     checked={selectedWords.includes(word)}
    //                     onChange={() => handleWordToggle(word)}
    //                     className="h-5 w-5"
    //                   />
    //                   <label className="text-lg">{word}</label>
    //                 </div>
    //               ))}
    //             </div>
    //             <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">    
    //             <button
    //                 className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
    //                 type="button"
    //                 onClick={() => handleRequestClose()}
    //               >
    //                 Close
    //               </button>
    //               <button
    //                 className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
    //                 type="button"
    //                 onClick={handlePaste}
    //               >
    //                 Paste
    //               </button>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    //     </>
    //   ) : null}
    // </>
  );
};

export default WordModal;
