
import './App.css';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';
import ProtectedRoute from './ProtectedRoute';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Enquiries from './components/enquiries/Enquiry';
import EnquiryDetails from './components/enquiries/EnquiryDetails';
import { initializeAuth, SET_USERDATA, signIn } from './redux/authReducer';
import Cookies from 'js-cookie'
import { instance } from './utils/axios';
import Login from './components/views/login/Login';
import Home from './components/views/home/Home';



function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeAuth());
  }, [dispatch]);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = Cookies.get("immersifyToken");
      const userId = Cookies.get("userId");
      if ( userId && token) {
        try {
          const response = await instance.get(`/auth/getUser/${userId}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          console.log("reposen of innital xall", response);
          dispatch(signIn({ token, userId: userId }))
          console.log("user data ", response?.data?.data);
          
          dispatch(SET_USERDATA(response.data?.data));
        } catch (error) {
          console.error('Failed to fetch user data:', error);
          // Handle error (e.g., logout user if token is invalid)
        }
      }
    };

    fetchUserData();
  }, [ dispatch]);

  console.log("is authenticated ", isAuthenticated);
  
  
  return (
    <>
    
      <HashRouter basename='/'>
        <Navbar isAuthenticated={isAuthenticated} />
        <Routes>
          <Route exact path='/' element={ isAuthenticated ? <Navigate to="/home" replace /> : <Login />}  />
          <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
          />
          <Route
          path="/enquiries"
          element={
            <ProtectedRoute>
              <Enquiries />
            </ProtectedRoute>
          }
          />
          <Route
            path="/enquiries/:id"
            element={
              <ProtectedRoute>
                <EnquiryDetails />
              </ProtectedRoute>
            }
          />
        <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </HashRouter>
      <ToastContainer />
    </>
  );
}

export default App;
