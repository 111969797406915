// src/redux/authReducer.js
import { createSlice } from "@reduxjs/toolkit";
import Cookies from 'js-cookie'
// import {
//   getToken,
//   removeToken,
//   setToken,
//   getDecodedToken,
//   isTokenExpired,
// } from "../utils/authUtils";

const initialState = {
  token: null,
  userId: null,
  isAuthenticated: false,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn(state, action) {
      const { token, userId } = action.payload;
      state.token = token;
      state.userId = userId;
      state.isAuthenticated = true;
      Cookies.set('immersifyToken', token, { expires: 1 });
      Cookies.set('userId', userId, { expires: 1 });

      console.log(state.isAuthenticated);
      
      // setToken(token);
    },
    signOut(state) {
      state.token = null;
      state.userId = null;
      state.isAuthenticated = false;
      Cookies.remove('immersifyToken')
      Cookies.remove('userId')
      // removeToken();
    },
    // initializeAuth(state) {
    //   const token = getToken();
    //   if (token && !isTokenExpired(token)) {
    //     const decodedToken = getDecodedToken(token);
    //     state.token = token;
    //     state.userId = decodedToken._id;
    //     state.isAuthenticated = true;
    //   } else {
    //     removeToken();
    //   }
    // },
    initializeAuth(state) {
      // const token = Cookies.get("immersifyToken");
      // const userId = Cookies.get("userId");
      // if (token && userId) {
      //   state.token = token;
      //   state.userId = userId;
      //   state.isAuthenticated = true;
      // } else {
      //   state.token = null;
      //   state.userId = null;
      //   state.isAuthenticated = false;
      //   state.user = null;
      // }
    },
    SET_USERDATA: (state, action) => {
      state.user = action.payload
    },
  },
});

export const { signIn, signOut, initializeAuth, SET_USERDATA } =
  authSlice.actions;

export default authSlice.reducer;
