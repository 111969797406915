import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { errorToaster } from "../../../ReactToastify";
import { useSelector } from "react-redux";
import VCard from "vcard-creator";


export const SaveContactModal = ({ showContactModal, handleCloseSaveContactModal, dataForSaveContact }) => {

    const [sendingMessage, setSendingMessage] = useState(false);
    // const [message, setMessage] = useState('')
    // const userData = useSelector(state  => state.auth.user)

//     useEffect(() => {
//         console.log(dataForWhatsapp);
        
//         const whatsappMSG = `Dear ${dataForWhatsapp?.viewerName ? dataForWhatsapp?.viewerName : 'User'},
//         \nThank you for attending our event! We truly appreciate your time and support. If you have any questions or need further information, feel free to reach out.
//         \nWe look forward to staying in touch!
//         \nBest regards,
// IMMARSIFY`;
//         const whatsappMessage =userData?.defaultMessage?.Whatsapp ? encodeURIComponent(userData?.defaultMessage?.Whatsapp) : encodeURIComponent(whatsappMSG);
//         setMessage(decodeURIComponent(whatsappMessage))
//     },[dataForWhatsapp])

    // const handleSendWhatsappMessage = () => {
    //     setSendingMessage(true)
    //     const newPhone = normalizePhoneNumber(dataForWhatsapp?.phone)
    
    //     // const whatsappMessage = userData?.defaultMessage?.Whatsapp ? encodeURIComponent(userData?.defaultMessage?.Whatsapp) : encodeURIComponent(whatsappMSG);
    //     const url = `https://wa.me/${newPhone}?text=${message}`;
    //     window.open(url, "_self");
    //     setSendingMessage(false)
    //   }

    const handleSaveContact = (enquiry) => {
        // Create a new vCard
        const myVCard = new VCard();
    
        // Add contact information
        myVCard
          .addName(enquiry?.viewerName)
          .addPhoneNumber(normalizePhoneNumber(enquiry?.phone), "MOBILE")
          .addEmail(enquiry?.email)
          .addCompany(enquiry?.company || "")
          .addAddress(
            "",
            "",
            enquiry?.address,
            enquiry?.city,
            enquiry?.state,
            enquiry?.pincode,
            "WORK"
          )
          .addURL(enquiry?.website || "");
    
        // Generate vCard as string
        const vCardString = myVCard.toString();
    
        // Create a Blob with the vCard data
        const blob = new Blob([vCardString], { type: "text/vcard" });
        const url = window.URL.createObjectURL(blob);
    
        // Create a temporary anchor element and trigger download
        const tempLink = document.createElement("a");
        tempLink.href = url;
        tempLink.setAttribute("download", `${enquiry?.viewerName}.vcf`);
        tempLink.click();
    
        // Clean up
        window.URL.revokeObjectURL(url);
      };

      const normalizePhoneNumber = (phoneNumber) => {
        phoneNumber = phoneNumber?.replace(/\s+/g, ""); // Remove all spaces
    
        if (/[a-zA-Z]/.test(phoneNumber)) {
          return errorToaster("Phone number cannot contain letters!"); // Return as is if it contains letters
        }
    
        // If the number starts with +91 or 91 and has more than 10 characters
        if (phoneNumber.length > 10) {
          if (phoneNumber.startsWith("+91")) {
            phoneNumber = phoneNumber.substring(3); // Remove +91
          } else if (phoneNumber.startsWith("91")) {
            phoneNumber = phoneNumber.substring(2); // Remove 91
          }
        }
    
        // After removing country codes, ensure the length is 10
        if (phoneNumber.length > 10) {
          phoneNumber = phoneNumber.substring(phoneNumber.length - 10); // Keep only the last 10 digits
        }
    
        // If the resulting length is exactly 10, add the country code
        if (phoneNumber.length === 10) {
          phoneNumber = "91" + phoneNumber; // Add 91 country code
        }
    
        return phoneNumber;
      };

    return (
        <Dialog
            // open={showContactModal}
            open={showContactModal}
            onClose={handleCloseSaveContactModal}
            aria-labelledby="confirm-delete-title"
            aria-describedby="confirm-delete-description"
            classes={{
                paper: "bg-white rounded-lg p-4 max-w-md w-full",
            }}
        >
            <DialogTitle
                id="confirm-delete-title"
                className="text-lg font-semibold text-gray-900"
            >
                Do you want to save contact in your contact list?
            </DialogTitle>
            {/* <DialogContent className="text-sm text-gray-600">
                <div className="text-gray-800">
                    
                </div>
            </DialogContent> */}
            <DialogActions className="flex justify-end mt-4">
                <button
                    onClick={handleCloseSaveContactModal}
                    className="text-black bg-gray-200  px-4 py-2 rounded-md hover:text-gray-800"
                >
                    No
                </button>
                <button
                    disabled={sendingMessage}
                    onClick={() => {
                        handleSaveContact(dataForSaveContact);
                        handleCloseSaveContactModal()
                    }}
                    className="bg-green-600 px-4 py-2  rounded-md text-white hover:bg-green-700 ml-2"
                >
                    {/* {sendingMessage ? "Ye" : "Send"} */}
                    Yes
                </button>
            </DialogActions>
        </Dialog>
    );
};
