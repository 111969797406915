import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { SET_USERDATA, signIn } from "../../../redux/authReducer";
import { errorToaster, successToaster } from "../../ReactToastify";
import Loader from "../../Loader";
import immersifyLogo from "../../../assets/immersify.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { getDecodedToken, isTokenExpired } from "../utils/authUtils";
import Cookies from 'js-cookie'
import { instance } from "../../../utils/axios";

const Login = () => {
  const [formData, setFormData] = useState({
    identifier: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect( async() => {
  //   const token = localStorage.getItem("immersify");

  //   if (token && !isTokenExpired(token)) {

  //     const { _id } = await decodedTokenData(token);
  //     dispatch(signIn({token, userId : _id}));
  //     navigate("/home");
  //   } else {
  //     clearTokenData(); // Clear token data if expired or invalid
  //   }
  // }, [navigate, dispatch]);

  // const handleLogin = async () => {
  //   setIsLoading(true);
  //   const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.identifier);

  //   const payload = {
  //     [isEmail ? "email" : "username"]: formData.identifier.toLocaleLowerCase(),
  //     password: formData.password,
  //   };
  //   console.log("payload", payload);
  //   try {
  //     const response = await axios.post(
  //       `${baseURL}/auth/login`,
  //       payload
  //     );
  //     setIsLoading(false);
  //     const { token } = response.data?.data;
  //     const userId = response.data?.data?.user?._id;

  //     if (!isTokenExpired(token)) {
  //       dispatch(signIn({ token, userId }));
  //       // dispatch(setUserId(userId));
  //       localStorage.setItem("immersify", token);
  //       successToaster("Logged in Successfully!");
  //       navigate("/home");
  //     } else {
  //       clearTokenData();
  //       errorToaster("Session expired. Please log in again.");
  //       navigate("/login");
  //     }

  //     // dispatch(setToken(token));
  //     // dispatch(setUserId(userId));
  //     // localStorage.setItem('immersify', token);
  //     // successToaster('Logged in Successfully!')
  //     // navigate('/home');
  //   } catch (error) {
  //     console.error("Failed to login", error);
  //     console.log();
  //     setIsLoading(false);
  //     errorToaster(error?.response?.data?.message);
  //   }
  //   setIsLoading(false);
  // };

  // const handleLogin = async () => {
  //   setIsLoading(true);
  //   const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.identifier);

  //   const payload = {
  //     [isEmail ? "email" : "username"]: formData.identifier.toLowerCase(),
  //     password: formData.password,
  //   };

  //   try {
  //     const response = await axios.post(`${baseURL}/auth/login`, payload);
  //     setIsLoading(false);
  //     console.log("response ", response);

  //     //set cookie token for 1 day
  //     // setuserdata
  //     // set token
  //     // set userId
      
  //     // if (!isTokenExpired(token)) {
  //     //   const decodedToken = getDecodedToken(token);
  //     //   dispatch(signIn({ token, userId: decodedToken._id }));
  //     //   successToaster("Logged in Successfully!");
  //     //   SET_USERDATA(response?.data?.data)
  //     //   navigate("/home");
  //     // } else {
  //     //   errorToaster("Session expired. Please log in again.");
  //     // }
  //   } catch (error) {
  //     console.error("Failed to login", error);
  //     setIsLoading(false);
  //     errorToaster(error?.response?.data?.message || "Login failed");
  //   }
  // };

  const handleLogin = async () => {
    setIsLoading(true);
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.identifier);
  
    const payload = {
      [isEmail ? "email" : "username"]: formData.identifier.toLowerCase(),
      password: formData.password,
    };
  
    try {
      const response = await instance.post(`/auth/login_ocr`, payload);
      setIsLoading(false);
      console.log("response ", response);
  
      const { token, user } = response.data.data;
  
      // Store token in cookie with 1 day expiration
      Cookies.set('immersifyToken', token, { expires: 1 });
      Cookies.set('userId', user._id, { expires: 1 });
  
      // Store userData from response
      dispatch(SET_USERDATA(user));
  
      // Store token and userId
      dispatch(signIn({ token, userId: user._id }));
  
      successToaster("Logged in Successfully!", 3000);
      navigate("/home");
    } catch (error) {
      console.error("Failed to login", error);
      setIsLoading(false);
      errorToaster(error?.response?.data?.message || "Login failed", 3000);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="w-full p-6 m-auto bg-white rounded-2xl shadow-xl drop-shadow-2xl shadow-slate-200  lg:max-w-xl">
        {/* <h1 className="text-3xl font-semibold text-center text-purple-700 underline">
          Sign in with Immersify
        </h1> */}
        <img src={immersifyLogo} className=" mx-auto" alt="immersify" />
        <form className="mt-6">
          <div className="mb-2">
            <label
              htmlFor="identifier"
              className="block text-sm font-semibold text-gray-800"
            >
              Email or Username
            </label>
            <input
            id="identifier"
              type="text"
              name="identifier"
              onChange={handleChange}
              className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2 relative">
            <label
              htmlFor="password"
              className="block text-sm font-semibold text-gray-800"
            >
              Password
            </label>
            <div className=" relative">
            <input
              id="password"
              type={isPasswordVisible ? "text" : "password"}
              name="password"
              onChange={handleChange}
              className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
            <span
              className="absolute inset-y-0 right-4 flex items-center cursor-pointer"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              <FontAwesomeIcon icon={isPasswordVisible ? faEyeSlash : faEye} />
            </span>

            </div>
            
          </div>
          {/* <a href="#" className="text-xs text-purple-600 hover:underline">
            Forget Password?
          </a> */}
          <div className="mt-6">
            <button
              type="button"
              onClick={() => handleLogin()}
              className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
            >
              Login
            </button>
          </div>
        </form>
      </div>
      {isLoading && <Loader isLoading={isLoading} />}
    </div>
  );
};

export default Login;
